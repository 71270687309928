import { CircleCloseFilled } from '@element-plus/icons';
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PhaseSummaryItem from '@/modules/project-estimate/components/PhaseSummaryItem.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';
export default defineComponent({
    name: 'notification-drawer',
    props: ['data', 'projectData', 'date', 'totalFee'],
    emits: ['on-submit'],
    components: {
        CircleCloseFilled,
        PhaseSummaryItem
    },
    computed: {
        ...mapGetters(PROJECTS_STORE, ['openSummaryEstimateDrawer']),
        getFormattedDate() {
            return moment(this.date).format('DD/MM/YYYY');
        },
        getTotalEarning() {
            return this.data.reduce((sum, phase) => sum + this.getTotalCost(phase), 0);
        },
        getEstimateDuration() {
            return this.data.reduce((sum, phase) => sum + this.getDays(phase), 0);
        }
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['setOpenSummaryEstimateDrawer']),
        closeDrawer() {
            this.setOpenSummaryEstimateDrawer(false);
        },
        submitEstimate() {
            this.$emit('on-submit');
        },
        getDays(phase) {
            const totalDays = phase.projectStageTasks.reduce((sum, { duration }) => sum + duration, 0);
            if (totalDays > 0) {
                return totalDays;
            }
            return phase.totalDuration;
        },
        getTotalCost(phase) {
            if (phase.stageType === 'materials') {
                return phase.subTotal;
            }
            const totalEarning = phase.projectStageTasks.reduce((sum, { total }) => sum + total, 0);
            if (totalEarning > 0) {
                return totalEarning;
            }
            return phase.stageCost;
        },
    }
});
