import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import CurrencyFilter from '@/filters/currency.filter';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';
const MATERIAL = 'materials';
export default defineComponent({
    name: 'select-deposit-modal',
    emits: ['on-confirm', 'on-cancel'],
    props: ['phases', 'sumTotal', 'show'],
    components: {
        BaseModal
    },
    data() {
        return {
            MATERIAL,
            list: [],
            fullDeposit: '',
            isFullDepositRequired: false,
            projectQuote: { projectStages: [] } // placeholder only
        };
    },
    watch: {
        show: {
            immediate: true,
            deep: true,
            async handler(value) {
                if (value) {
                    await this.initialize();
                }
            }
        },
    },
    async created() {
    },
    methods: {
        ...mapActions(PROJECT_QUOTES_STORE, ['getTotalStageCost', 'calculateServiceFee']),
        async initialize() {
            const { phases } = this;
            this.projectQuote = { projectStages: phases };
            await this.setProjectStageServiceFee();
            this.list = this.normalizePhases();
            this.fullDeposit = CurrencyFilter.formatToCurrency(this.sumTotal);
        },
        onCheckFullDeposit(value) {
            if (value) {
                this.list = this.normalizePhases(true);
            }
            else {
                this.list = this.normalizePhases(false, true);
            }
        },
        normalizePhases(forceRequireDeposit = false, resetRequiredDeposit = false) {
            const { projectQuote } = this;
            return projectQuote.projectStages.map((phase) => {
                const amount = this.getTotalCost(phase);
                const isMaterial = phase.stageType === MATERIAL;
                return {
                    ...phase,
                    isMaterial,
                    stageCostDisplay: CurrencyFilter.formatToCurrency(amount),
                    requiredDeposit: forceRequireDeposit || (resetRequiredDeposit ? false : phase.requiredDeposit)
                };
            });
        },
        getTotalCost(phase) {
            if (phase.stageType === MATERIAL) {
                return phase.subTotal;
            }
            const totalEarning = phase.projectStageTasks.reduce((sum, { total }) => sum + total, 0);
            if (totalEarning > 0) {
                return totalEarning;
            }
            return phase.stageCost;
        },
        async setProjectStageServiceFee() {
            const { projectQuote } = this;
            if (projectQuote.projectStages) {
                const getProjectStageServiceFeePromises = [];
                projectQuote.projectStages.map((projectStage, i) => {
                    getProjectStageServiceFeePromises.push(this.getProjectStageServiceFee(i));
                    return projectStage;
                });
                const getProjectStageServiceFeeResponses = await Promise.all(getProjectStageServiceFeePromises);
                if (getProjectStageServiceFeeResponses) {
                    projectQuote.projectStages = projectQuote.projectStages.map((projectStage, i) => {
                        const serviceFee = getProjectStageServiceFeeResponses[i];
                        const projectStageWithServiceFee = {
                            ...projectStage,
                            serviceFee,
                            stageCostWithFee: serviceFee + (projectStage.stageCost || projectStage.subTotal)
                        };
                        return projectStageWithServiceFee;
                    });
                }
            }
        },
        async getProjectStageServiceFee(stageIndex) {
            const { projectQuote } = this;
            const totalStageCost = await this.getTotalStageCost(projectQuote.projectStages);
            const totalServiceFee = await this.calculateServiceFee({
                projectId: this.selectedProject?.id,
                projectStages: projectQuote.projectStages
            });
            const totalCost = projectQuote.projectStages[stageIndex].stageCost || projectQuote.projectStages[stageIndex].subTotal;
            const stageCostPercentage = (totalCost) / totalStageCost;
            const stageServiceFee = totalServiceFee * stageCostPercentage;
            return parseFloat(stageServiceFee.toFixed(2));
        },
        onConfirm() {
            this.$emit('on-confirm', this.list);
        },
        onCancel() {
            this.$emit('on-cancel');
        }
    }
});
