import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';
export default defineComponent({
    emits: ['set-note-value'],
    props: ['passNoteValue'],
    data() {
        return {
            note: ''
        };
    },
    watch: {
        note: {
            immediate: true,
            deep: true,
            handler(newNote, oldNote) {
                if (newNote !== oldNote) {
                    this.setNoteValue(newNote); // Update the state with newNote
                }
            }
        },
        passNoteValue: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value) {
                    this.note = value;
                }
            }
        }
    },
    created() {
    },
    computed: {
        ...mapGetters(JOB_TEMPLATE_STORE, ['estimateForm', 'selectedPhaseIndex'])
    },
    methods: {
        initialize() {
        },
        setNoteValue(value) {
            this.$emit('set-note-value', value);
        }
    }
});
