<template>
  <section class="estimate-summary-header">
    <GlobalDynamicHeader :hasStartCustomComponent="true" :hasEndCustomComponent="true">
      <template #start-custom-component>
        <p class="estimate-summary-header__title default-text">Summary</p>
      </template>
      <template #end-custom-component>
        <div>
            <div class="flex-row ai-c" v-if="!isQuoteAccepted">
              <div class="estimate-summary-header__select-state flex-row ai-c" v-if="!isTemplateCreate">
                <el-radio-group v-model="type" class="flex-row ai-c" @change="onSetType">
                  <el-radio :label="estimateState.VARIABLE" size="large">Variable</el-radio>
                  <el-radio :label="estimateState.FIXED" size="large">Fixed</el-radio>
                </el-radio-group>
              </div>
              <SaveDraft class="hide-in-bigger-mobile" v-if="isOnEstimateForm && isTemplateCreate" @cancel="onCancel"/>
              <Button
                :isActive="isFormReady"
                :buttonText="getButtonText"
                @handle-click="onHandleClick"/>
            </div>
            <div class="estimate-summary-header__wrapper flex-row ai-c jc-fe" v-else>
                <div class="estimate-summary-header__agreement-action">
                  <el-checkbox v-model="agreeToCondition">
                    <p>By accepting the estimate you will agree with the<br> terms within the <span>Service Agreement</span></p>
                  </el-checkbox>
                </div>
                <div class="flex-row ai-c">
                  <Button
                    :isActive="agreeToCondition"
                    :loading="submitting"
                    buttonText="accept"
                    @handle-click="onAcceptJob"/>
                  <Button
                    :loading="submitting"
                    buttonText="reject"
                    @handle-click="onRejectJob"/>
                </div>
            </div>
        </div>
      </template>
    </GlobalDynamicHeader>
  </section>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import GlobalDynamicHeader from '@/core/components/common/header/GlobalDynamicHeader.vue';
import SaveDraft from '@/core/components/project/SaveDraft.vue';
import Button from '@/core/components/ui/Button.vue';
import estimateState from '@/core/constants/estimate';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';
import { PROJECTS_STORE } from '@/store/modules/projects';

const EDIT = 'template-edit';

export default defineComponent({
  components: {
    GlobalDynamicHeader,
    Button,
    SaveDraft
  },

  emits: ['on-handle-click', 'open-accept-job-confirmation-modal'],

  props: ['isQuoteAccepted', 'isTemplateCreate', 'canQuoteSubmit'],

  data() {
    return {
      estimateState,
      agreeToCondition: false,
      submitting: false,
      EDIT
    };
  },

  computed: {
    ...mapGetters(JOB_TEMPLATE_STORE, [
      'estimateForm',
      'selectedPhaseIndex',
      'templateName',
      'showCreateTemplateConfirmationModal',
      'showSubmitEstimateConfirmationModal',
      'isFormReady',
      'formData',
      'selectDepositModal',
      'isOnEstimateForm'
    ]),

    ...mapGetters(PROJECTS_STORE, [
      'currentSelectedProject'
    ]),

    ...mapGetters(PROJECT_QUOTES_STORE, [
      'type'
    ]),

    isUpdate() {
      return this.currentSelectedProject?.projectQuotes?.length && this.canQuoteSubmit;
    },

    getButtonText() {
      if (this.isTemplateCreate) {
        return this.currentRouteName === EDIT ? 'Update template' : 'Save as a template';
      }
      if (this.isUpdate) {
        return 'Update estimate';
      }
      return this.canQuoteSubmit ? 'Submit estimate' : '';
    },

    currentRouteName() {
      return this.$route.name;
    }
  },

  methods: {
    ...mapActions(PROJECT_QUOTES_STORE, [
      'setType'
    ]),

    ...mapActions(JOB_TEMPLATE_STORE, ['setEstimateForm', 'setIsOnEstimateForm']),

    onHandleClick() {
      this.$emit('on-handle-click', this.isUpdate);
    },

    onAcceptJob() {
      this.$emit('open-accept-job-confirmation-modal');
    },

    onSetType(event) {
      this.setType(event);
    },

    onCancel() {
      const { params } = this.$route;
      if (params?.id) {
        this.$router.replace({ name: 'templates' });
      }

      this.setEstimateForm({
        projectStages: [
          {
            name: '',
            stageType: 'labour',
            totalDuration: 0,
            durationType: 1,
            stageCost: 0,
            projectStageTasks: [],
            isNew: true,
            isUsePhaseTotal: null,
            requiredDeposit: false,
            vat: 0,
            subTotal: 0,
            note: ''
          }
        ]
      });

      this.setIsOnEstimateForm(false);
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../../../assets/scss/mixins/media-query" as *;
@use "../../../../../assets/scss/mixins/" as *;

  .estimate-summary-header {
    &__title {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 85.714% */
        letter-spacing: 0.25px;
    }

    &__select-state {
      border-radius: 10px;
      border: 1px solid rgba(12, 15, 74, 0.10);
      box-shadow: 0px 0px 10px 0px rgba(42, 42, 42, 0.02);
      padding: 0 1rem;
      box-sizing: border-box;
    }

    &__wrapper {
      padding: 0 1rem;
      margin-top: .5rem;
      box-sizing: border-box;
    }

    &__agreement-action {
      border: 1px solid rgba(12, 15, 74, 0.1);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: .5rem 1rem;
      box-sizing: border-box;
      height: 50px;

      .el-checkbox__label {
          font-style: normal;
          font-size: 13px;
          line-height: 16px;
          color: rgba(255, 255, 255, 1);
      }
      :deep(.el-checkbox__input .el-checkbox__inner) {
          border-radius: 100px;
          background: rgba(12, 15, 74, 0.15);
          border: 1 solid rgba(12, 15, 74, 0.15);
          width: 18px;
          height: 18px;
      }
      :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
          display: none;
      }
      :deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
          border: 0px solid rgba(12, 15, 74, 0.15);
          border-radius: 100px;
          background: rgba(12, 15, 74, 0.15);
          transition: 0.2s ease-in-out;
      }
      :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
          border-radius: 100px;
          border: 4px solid #ffa500;
          background: #ffa500;
          transition: 0.2s ease-in-out;
      }

      p {
          margin: 0;
          font-family: Mulish;
          font-size: 10px;
          font-weight: 600;
          line-height: 15px;
          letter-spacing: 0.25px;
          text-align: left;
          color: rgba(12, 15, 74, 0.5);

          span {
              font-family: Mulish;
              font-size: 10px;
              font-weight: 400;
              line-height: 15px;
              letter-spacing: 0.25px;
              text-align: left;
              color: rgba(250, 161, 0, 1);
          }
      }
    }
  }

@include media-sm-max-width() {
  .estimate-summary-header {
    &__title {
      font-size: 18px;
    }
  }
}
</style>
