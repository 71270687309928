<template>
    <section class="estimate-header-form flex-column jc-sb">
      <div class="estimate-header-form__container date flex-row ai-c jc-sb" v-if="!isTemplateCreate">
        <div class="estimate-header-form__wrapper date flex-row ai-c gap-half">
          <p class="estimate-header-form__text default-text">Project start date:</p>
          <el-form :model="formData">
            <el-date-picker
              v-model="formData.suggestedStartDate"
              type="date"
              :format="DATE_FORMAT"
              :disabled="isFormReadOnly"
              :value-format="SYSTEM_DATE_FORMAT"
              :placeholder="DATE_FORMAT"
              :disabledDate="disabledProjectStartDate">
            </el-date-picker>
          </el-form>
        </div>
        <!-- <ActionItemComponent
          fontSize="14px"
          label="Invite"
          icon="invite.svg"
          iconBgColor="#FFF"
          :disable="isFormReadOnly"
          @call-to-action="onOpenInviteTradeContractorModal"/> -->
      </div>

      <div class="estimate-header-form__container flex-row ai-c jc-sb" v-else>
        <div class="estimate-header-form__wrapper flex-row ai-c gap-half" style="width: 50% !important;">
           <el-input
             type="text"
            v-model="tempName"
            :readonly="isReadOnly"
            :disabled="isFormReadOnly"
            @change="setIsReadOnly"></el-input>
           <ActionItemComponent
              fontSize="14px"
              label="Edit"
              icon="pen-filled-yellow-icon.svg"
              @call-to-action="setIsReadOnly"/>
        </div>
      </div>

      <div class="estimate-header-form__container flex-row ai-c jc-sb">
        <div class="estimate-header-form__wrapper flex-row ai-c">
          <inline-svg
           class="estimate-header-form__move-icon" width="20px" height="20px"
           :src="require(`@/assets/icons/move-left.svg`)" @click="movePhaseListToLeft"/>
          <div class="estimate-header-form__phase-wrapper flex-row ai-c" ref="phaseWrapper">
            <p class="estimate-header-form__phase-text default-text"
              v-for="(projectStage, index) in estimateForm.projectStages" :key="index"
              :class="{'active': selectedPhaseIndex === index}"
              @click="setActiveIndex(index)">
                {{ estimatePhaseHelper.getPhaseText(estimateForm, projectStage.stageType, index) }}
              </p>
          </div>
          <inline-svg
            class="estimate-header-form__move-icon" width="20px" height="20px"
            :src="require(`@/assets/icons/move-right.svg`)" @click="movePhaseListToRight" />
        </div>
        <ActionItemComponent
          iconSize="25"
          fontSize="14px"
          label="New phase"
          icon="add-button-icon-yellow.svg"
          :disable="isFormReadOnly"
          @call-to-action="addPhase"/>
      </div>

      <!-- MODALS -->
      <InviteTradeContractorsModal :hasUpdated="hasUpdated" :show="tradeContractorsModal" @invite="onInviteTradeContractors" @on-close="setInviteTradeContractorsModal(false)"/>
    </section>
</template>
<script>
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import InviteTradeContractorsModal from '@/core/components/common/modals/InviteTradeContractorsModal.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import { DATE_FORMAT, SYSTEM_DATE_FORMAT } from '@/core/constants';
import estimatePhaseHelper from '@/core/helpers/estimate-phase.helper';
import { ProjectStageTypeEnum } from '@/core/packages/shared-library';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';

export default defineComponent({
  components: {
    ActionItemComponent,
    InviteTradeContractorsModal
  },

  props: {
    isTemplateCreate: {
      type: Boolean
    },

    isQuoteAccepted: {
      type: Boolean
    }
  },

  data() {
    return {
      DATE_FORMAT,
      SYSTEM_DATE_FORMAT,

      isReadOnly: true,

      tradeContractorsModal: false,
      hasUpdated: false,

      tempName: '',

      disabledProjectStartDate(date) {
        return moment(date).utc() < moment().startOf('day');
      },

      count: 10,

      isAnimating: false,
      ProjectStageTypeEnum,
      estimatePhaseHelper
    };
  },

  computed: {
    ...mapGetters(JOB_TEMPLATE_STORE, [
      'estimateForm',
      'selectedPhaseIndex',
      'templateName',
      'formData',
      'isFormReadOnly'
    ]),
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions(JOB_TEMPLATE_STORE, [
      'setSelectedPhaseIndex',
      'setEstimateForm',
      'addProjectStage'
    ]),

    initialize() {
      this.tempName = this.templateName;
      this.setSelectedPhaseIndex(0);
    },

    setInviteTradeContractorsModal(event) {
      this.tradeContractorsModal = event;
    },

    onInviteTradeContractors() {
      // ..
    },

    setIsReadOnly() {
      this.isReadOnly = !this.isReadOnly;
    },

    movePhaseListToRight() {
      const { phaseWrapper } = this.$refs;

      if (phaseWrapper) {
        phaseWrapper.scrollLeft += 100;
      }
    },

    movePhaseListToLeft() {
      const { phaseWrapper } = this.$refs;

      if (phaseWrapper) {
        phaseWrapper.scrollLeft -= 100;
      }
    },

    setActiveIndex(index) {
      this.setSelectedPhaseIndex(index);
    },

    addPhase() {
      if (!this.isFormReadOnly) {
        this.addProjectStage();
        this.setSelectedPhaseIndex(this.estimateForm.projectStages.length - 1);
      }
    },

    onOpenInviteTradeContractorModal() {
      if (!this.isFormReadOnly) {
        this.setInviteTradeContractorsModal(true);
      }
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../../../assets/scss/mixins/media-query" as *;
@use "../../../../../assets/scss/mixins/" as *;

.estimate-header-form {
  background: rgba(12, 15, 74, 0.05);
  padding: 1.5rem;

  &__wrapper {
    width: 100%;
  }
  &__phase-wrapper {
    width: 100%;
    overflow-x: hidden;
    max-width: 400px;
    transition: all .3s ease-in-out;
  }
  &__phase-text {
    white-space: nowrap;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  &__phase-text.active {
    font-weight: 700;
    &::after {
      content: "";
      position: absolute;
      width: 80%;
      height: 2px;
      background: #5DB9E7;
      bottom: 0;
      left: 4px;
      text-align: center;
      z-index: 2;
    }
  }
  &__move-icon {
    background: #FFF;
    border-radius: 100%;
    cursor: pointer;
  }
}
.el-input {
  :deep(.el-input__inner) {
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(12, 15, 74, 0.20);
    border-radius: 0;
    padding: 0;
  }
}
:deep(.el-date-editor) {
    border: none;
    border-radius: 0;
  }

:deep(.el-date-editor .el-input__inner) {
    background-color: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(12, 15, 74, 0.2);
    padding-left: 0;
  }

  :deep(.input-icon .el-input__inner) {
    // padding-left: 12px;
  }

  :deep(.el-date-editor .el-input__prefix) {
    left: auto;
    right: 0;
    color: #faa100;
  }

  :deep(.el-date-editor .el-input__suffix) {
    right: 1.5rem;
  }

@include media-xs-max-width() {
  .estimate-header-form {
    &__wrapper.date {
      flex-direction: column;
      align-items: flex-start;
    }
    &__container.date {
      align-items: flex-start;
    }
    &__phase-wrapper {
      max-width: 150px;
    }
  }
}
</style>
