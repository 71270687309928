<template>
    <div class="material-form flex-column" v-for="(phase, i) in estimateForm.projectStages" :key="i" v-show="i === selectedPhaseIndex">
      <el-form class="flex-column" :model="phase">
        <el-form-item>
          <section class="material-form__container grid-2fr">
            <div class="material-form__wrapper flex-row ai-c gap-half">
              <div class="material-form__input-container">
                <el-select v-model="phase.stageType" class="m-2" placeholder="Type" size="large" :readonly="isFormReadOnly">
                    <el-option v-for="item in stageTypeOption" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </div>
              <el-divider direction="vertical" class="hide-in-mobile"/>
              <div class="material-form__input-container with-icon">
                <el-input
                  v-model="phase.subTotalDisplay"
                  @keypress="isNumber($event, phase.subTotalDisplay)"
                  @input="onPhaseCostInput(i)"
                  @blur="onPhaseCostBlur(i)"
                  placeholder="Total Cost"
                  :readonly="isFormReadOnly"
                  :disabled="phase.isUsePhaseTotal === false"
                  >
                  <template v-slot:prefix>£</template>
                </el-input>
              </div>
              <el-divider direction="vertical" class="hide-in-mobile"/>
            </div>
            <!-- <div class="material-form__wrapper flex-row ai-c gap-half">
              <el-form-item>
                  <div class="material-form__input-container flex-row ai-c gap-0">
                    <el-input type="text" placeholder="VAT" :readonly="true"/>
                    <div class="material-form__options">
                      <el-select v-model="phase.vat" :readonly="isFormReadOnly"
                        class="material-form__select-child">
                        <el-option v-for="item in vatTypeOption" :key="item.value" :label="item.label"
                        :value="item.value" />
                      </el-select>
                    </div>
                  </div>
              </el-form-item>
              <el-divider direction="vertical" class="hide-in-mobile"/>
              <StatusState style="width: 100%" :status="$filters.formatToCurrency(phase.subTotal) || '£ Total material cost'"
                bgColor="rgba(12, 15, 74, 0.05)" fontColor="#0C0F4A" boxShadow="none" padding=".7rem"/>
            </div> -->
          </section>
        </el-form-item>
      </el-form>
      <NoteComponent v-if="showNote"/>
      <EstimateActions
        :estimateForm="estimateForm"
        :isTemplateCreate="isTemplateCreate"
        @show-note="showNote = !showNote"
        :hasNote="showNote"
        @delete="onDeletePhase"
        @show-attachment-modal="onAddAttachment"/>
    </div>
</template>
<script>
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import EstimateActions from '@/core/components/common/forms/estimate-form/EstimateActions.vue';
// import StatusState from '@/core/components/ui/StatusState.vue';
import { DATE_FORMAT, SYSTEM_DATE_FORMAT } from '@/core/constants';
import NumberHelper from '@/core/helpers/number.helper';
import CurrencyFilter from '@/filters/currency.filter';
import NoteComponent from '@/modules/project-estimate/components/new-components/NoteComponent.vue';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';

export default defineComponent({
  components: {
    EstimateActions,
    // StatusState,
    NoteComponent
  },

  emits: [
    'on-input-phase-cost'
  ],

  props: ['isTemplateCreate'],

  data() {
    return {
      NumberHelper,
      DATE_FORMAT,
      SYSTEM_DATE_FORMAT,
      stageTypeOption: [
        {
          value: 'labour',
          label: 'Labour',
        },
        {
          value: 'materials',
          label: 'Materials',
        }
      ],
      isFormReadOnly: false,
      material: {
        stageType: 'materials',
        name: '',
        materialCost: null,
        vat: '',
        subTotal: ''
      },
      durationTypeOption: [
        {
          value: 1,
          label: 'Days',
        },
      ],

      defaultDurationType: 'Days',

      vatTypeOption: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 0.05,
          label: '5%',
        },
        {
          value: 0.20,
          label: '20%',
        },
      ],
      disabledPhaseStartDate(date) {
        return moment(date).utc() < moment().startOf('day');
      },

      showNote: false,
      CurrencyFilter
    };
  },

  computed: {
    ...mapGetters(JOB_TEMPLATE_STORE, ['estimateForm', 'selectedPhaseIndex'])
  },

  methods: {
    ...mapActions(JOB_TEMPLATE_STORE, ['setSelectedPhaseIndex', 'setShowUploadAttachmentModal']),

    isNumber(event, value) {
      return NumberHelper.isNumber(event, value);
    },

    setToDecimalFormat(value) {
      return NumberHelper.convertToDecimal(value);
    },

    onPhaseCostBlur(phaseIndex) {
      const subtotalDisplay = this.estimateForm.projectStages[phaseIndex].subTotalDisplay;
      this.estimateForm.projectStages[phaseIndex].subTotal = this.setToDecimalFormat(CurrencyFilter.toNumber(subtotalDisplay));
      this.estimateForm.projectStages[phaseIndex].subTotalDisplay = CurrencyFilter.formatCurrencyWithoutSymbol(subtotalDisplay);
      this.onPhaseCostInput(phaseIndex);
    },

    onPhaseCostInput(index) {
      this.$emit('on-input-phase-cost', index);
    },

    onDeletePhase() {
      this.estimateForm.projectStages = this.estimateForm.projectStages.filter((x, i) => i !== this.selectedPhaseIndex);
      this.setSelectedPhaseIndex(this.estimateForm.projectStages.length - 1);
    },
  }
});
</script>
<style lang="scss" scoped>
.material-form {
  width: 100%;

  &__container.grid {
    grid-template-columns: 1.5fr .5fr;
  }
  &__container.grid-1fr {
    grid-template-columns: 1fr !important;
  }
  &__container.grid-2fr {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }

  &__input-container {
    width: 100%;
  }
  &__input-container.with-icon {
    .el-input {
      :deep(.el-input__inner) {
        background: transparent;
        border: none;
        border-bottom: 1px solid rgba(12, 15, 74, 0.20);
        border-radius: 0;
        padding-left: 1.5rem;
      }
    }
  }
}
.el-form {
  :deep(.el-form-item) {
    margin: 0;
  }
}
.el-input {
  :deep(.el-input__inner) {
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(12, 15, 74, 0.20);
    border-radius: 0;
    padding: 0;
  }
}
.el-select {
  :deep(.el-input__inner) {
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(12, 15, 74, 0.20);
    border-radius: 0;
    padding: 0;
  }
}

:deep(.el-date-editor) {
    border: none;
    border-radius: 0;
  }

:deep(.el-date-editor .el-input__inner) {
    background-color: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(12, 15, 74, 0.2);
    padding-left: 0;
  }

  :deep(.input-icon .el-input__inner) {
    // padding-left: 12px;
  }

  :deep(.el-date-editor .el-input__prefix) {
    left: auto;
    right: 0;
    color: #faa100;
  }

  :deep(.el-date-editor .el-input__suffix) {
    right: 1.5rem;
  }
</style>
