<template>
<BaseModal
  :show="show"
  :showCancelBtn="false"
  :showSubmitBtn="false"
  :showDefaultCloseBtn="false"
  @cancel="onCancel"
  :hasActions="false"
  customClass="xs-modal"
>
  <div class="modal-content flex-column jc-sb">
    <div class="flex-column jc-sb">
        <div class="modal-content__modal-header flex-row jc-sb ai-c">
            <h2 class="modal-header__title default-text">Upload Attachment</h2>
            <div class="modal-content__header-icon">
              <inline-svg class="cursor-pointer" @click="onCancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
            </div>
        </div>
        <div class="modal-content__upload" v-if="isTradesperson">
            <!-- <UploadForm
              :record="estimateForm.projectStages[selectedPhaseIndex]"
              :attachmentSources="estimateForm.projectStages[selectedPhaseIndex].projectStageAttachments"
              :useCustomUploadIcon="true"
              :allowMultiple="true"
              listType="picture"
              @on-upload-success="onUploadSuccess"
              @on-progress-upload="onProgressUpload"
              /> -->
             <UploadForm
                :uploadUrl="getUploadUrl"
                :hasCustomFnRemoveFile="true"
                :record="form"
                :useCustomUploadIcon="true"
                :allowMultiple="true"
                listType="picture"
                @on-upload-success="onUploadSuccess"
                @on-progress-upload="onProgressUpload"
                @custom-fn-remove-file="handleRemoveFile"
                />
        </div>
    </div>
    <div class="modal-content__actions grid">
      <el-button @click="onSave" :loading="isUploading" :disabled="isUploading" class="button active">SAVE</el-button>
      <el-button @click="onCancel" :disabled="isUploading" class="button">CANCEL</el-button>
    </div>
  </div>
</BaseModal>
</template>
<script>

import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';

import UploadForm from '@/core/components/common/forms/upload/Upload.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
// import { ImageFileTypes } from '@/core/helpers/file.helper';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';
// import { PROJECT_STAGES_CACHE } from '@/store/modules/attachment-cache/constants';

export default defineComponent({
  name: 'phase-attachment-modal',
  emits: ['close', 'handleRemoveFile', 'update-attachments-state'],
  components: {
    BaseModal,
    UploadForm,
  },
  props: ['show'],

  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      isUploading: false,

      loadingAttachments: false,
      attachmentUrls: [],
      imageAttachments: [],
      documentAttachments: [],
      form: {
        attachments: [],
        description: ''
      },
    };
  },

  created() {
    this.form.attachments = [];
  },

  computed: {
    ...mapGetters(['isProjectOwner', 'isTradesperson']),
    ...mapGetters(JOB_TEMPLATE_STORE, ['estimateForm', 'selectedPhaseIndex']),
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),
    ...mapActions(JOB_TEMPLATE_STORE, ['']),

    onCancel() {
      this.form.attachments = [];
      if (!this.isUploading) {
        this.$emit('close');
      }
    },

    onClose() {
      this.form.attachments = [];
      this.$emit('close');
    },

    onSave() {
      this.$emit('close');
    },

    onUploadSuccess() {
      // this.$emit('update-attachments-state', { attachments: this.form.attachments, projectStageId: this.phase.id });
      this.isUploading = false;
    },

    onProgressUpload() {
      this.isUploading = true;
    },

    handleRemoveFile(attachment) {
      this.$emit('handleRemoveFile', attachment);
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.15000000596046448px;
    margin: 0;
    color: #0C0F4A;
}
.modal-content {
  width: 400px;
  min-height: 590px;

  &__title {
    font-size: 24px;
    text-align: center;
  }
  &__input-container {
    gap: .1rem;
  }
  &__input-name {
    font-size: 12px;
    line-height: 22px;
  }
  &__text-description {
    font-size: 12px;
    font-weight: 500;
    color: rgba(12, 15, 74, 0.5);
  }
  &__actions {
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }

  &__wrapper {
    width: 100%;
  }

  &__secondary-header {
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0px;
  }

  :deep(.el-textarea__inner) {
    background: rgba(12, 15, 74, 0.05);
    border:none;
    border-radius: 8px;
  }

  &__attachment-list {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    grid-gap: 1rem;
  }
  &__image {
    width: 100% !important;
    border-radius: 8px;
  }

  &__upload {
    max-height: 493px;
    overflow-y: auto;
  }
}
.button {
    height: 40px;
    padding: 0 2rem;
    box-sizing: border-box;
    background-color: rgba(12, 15, 74, 0.05);
    border: none;
    border-radius: 7px;
    @extend .default-text;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: rgba(12, 15, 74, 0.5);

    &:hover {
        background-color: rgba(12, 15, 74, 0.05);
        color: rgba(12, 15, 74, 0.5);
    }
}
.active {
    background-color: #FFA500;
    color: #fff;

    &:hover {
        background-color: #FFA500;
        color: #fff;
    }
}
:deep(.el-input__inner) {
  border-radius: 8px;
}
.el-divider {
  margin: 0;
}

  .el-image {
    width: 100px;
    height: 100px;

    ::v-deep {
      img {
        object-fit: cover;
      }
    }
  }

@include media-xs-max-width() {
  .modal-content {
    width: 100%;
    min-height: 400px;
  }
}
</style>
