<template>
    <section class="estimate-summary flex-column hide-in-mobile">
      <EstimateSummaryHeader
        :isTemplateCreate="isTemplateCreate"
        :isQuoteAccepted="isQuoteAccepted"
        :canQuoteSubmit="canQuoteSubmit"
        @on-handle-click="onHandleClick($event)"
        @open-accept-job-confirmation-modal="onOpenAcceptJobConfirmationModal"/>
      <div class="estimate-summary__card flex-column gap-2 jc-sb">
        <div class="estimate-summary__wrapper flex-row ai-c" v-if="!isTemplateCreate">
          <SingleDataWidget
            :data="getFormattedDate"
            width="100%"
            height="70px"
            gap=".2rem"
            label="Project start date"/>
          <SingleDataWidget
            :data="estimateForm?.projectStages.length.toString()"
            width="100%"
            height="70px"
            gap=".2rem"
            label="Total Phases"/>
          <SingleDataWidget
            :data="getEstimateDuration"
            width="100%"
            height="70px"
            gap=".2rem"
            label="Estimated Duration"/>
        </div>
        <el-scrollbar>
          <div class="estimate-summary__scrollbar-content flex-column">
            <ProjectStageSummaryItem
              v-for="(phase, i) in estimateForm.projectStages" :key="i"
              :data="phase" :index="i"/>
          </div>
        </el-scrollbar>
        <div class="estimate-summary__container flex-column">
          <DataWithLabel
            label="Your total earnings (Inc. Vat)"
            :data="estimateCalculatorHelper.formattedTotalEarning(estimateForm)"
            :dataFontBold="true"/>
          <!-- <DataWithLabel label="VAT (0%)" data="£0.00" :dataFontBold="true"/> -->
          <DataWithLabel
            :label="percentLabel"
            :data="estimateCalculatorHelper.formatCurrency(totalFee)"
            :dataFontBold="true"/>
          <el-divider/>
          <DataWithLabel
            :data="estimateCalculatorHelper.calculateTotalCustomerPayment(estimateForm, totalFee)"
            :dataFontBold="true">
            <template #custom-label>
              <p class="estimate-summary__text bold default-text">Customer pays</p>
            </template>
          </DataWithLabel>
        </div>
      </div>

      <!-- MODALS -->
      <CreateTemplateConfirmationModal
        :show="showCreateTemplateConfirmationModal"
         @on-save="onSaveTemplate"
         :loading="isSubmitting"
        @close="onCloseConfirmationModal"/>
      <SubmitEstimateConfirmationModal
        :show="showSubmitEstimateConfirmationModal"
        :loading="loading"
        @on-save="onSubmitEstimate($event)"
        @close="onCloseConfirmationModal"/>
      <ConfirmUpdateEstimate
        :show="showUpdateEstimateConfirmationModal"
        :loading="loading"
        @on-update="onSubmitEstimate($event)"
        @close="onCloseConfirmationModal"/>
      <SelectDepositModal
        v-if="estimateForm.projectStages?.length"
        :show="selectDepositModal"
        :sumTotal="estimateCalculatorHelper.calculateTotalCustomerPaymentRaw(estimateForm, totalFee)"
        :phases="estimateForm.projectStages"
        @on-confirm="onOpenEstimateConfirmationModal"
        @on-cancel="onCloseModals"/>
      <ConfirmJobModal
        :show="confirmJobModal"
        @on-confirm="onAcceptService"
        @on-cancel="onCloseModals"/>
      <ServiceAgreementModal
        v-if="projectQuote"
        :show="serviceAgreementModal"
        :project="currentSelectedProject"
        :projectQuote="projectQuote"
        :loading="isLoadingSubmit"
        @on-confirm="submitAcceptJob"
        @on-cancel="onCloseModals"/>
    </section>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import EstimateSummaryHeader from '@/core/components/common/forms/estimate-form/EstimateSummaryHeader.vue';
import ConfirmUpdateEstimate from '@/core/components/common/modals/ConfirmUpdateEstimate.vue';
import CreateTemplateConfirmationModal from '@/core/components/common/modals/CreateTemplateConfirmationModal.vue';
import SubmitEstimateConfirmationModal from '@/core/components/common/modals/SubmitEstimateConfirmationModal.vue';
import ProjectStageSummaryItem from '@/core/components/jobs/estimates/ProjectStageSummaryItem.vue';
import DataWithLabel from '@/core/components/ui/DataWithLabel.vue';
import SingleDataWidget from '@/core/components/ui/SingleDataWidget.vue';
import estimateState from '@/core/constants/estimate';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/messages';
import estimateCalculatorHelper from '@/core/helpers/estimate-calculator.helper';
import { ProjectQuoteStatusEnum } from '@/core/packages/shared-library';
import ServiceAgreementModal from '@/modules/project-details-children/components/modal/ServiceAgreementModal.vue';
import ConfirmJobModal from '@/modules/project-estimate/modals/ConfirmJobModal.vue';
import SelectDepositModal from '@/modules/project-estimate/modals/SelectDepositModal.vue';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';
import { PROJECTS_STORE } from '@/store/modules/projects';

export default defineComponent({
  components: {
    ProjectStageSummaryItem,
    SingleDataWidget,
    DataWithLabel,
    CreateTemplateConfirmationModal,
    SubmitEstimateConfirmationModal,
    SelectDepositModal,
    EstimateSummaryHeader,
    ConfirmJobModal,
    ServiceAgreementModal,
    ConfirmUpdateEstimate
  },

  emits: ['back-to-list', 'on-submit-estimate', 'route-to-overview'],

  props: ['isTemplateCreate', 'canQuoteSubmit', 'isQuoteAccepted', 'projectQuote', 'loading', 'summaryTotal'],

  data() {
    return {
      estimateCalculatorHelper,
      isSubmitting: false,
      state: '',
      estimateState,
      confirmJobModal: false,
      serviceAgreementModal: false,
      isLoadingSubmit: false,
      isSubmitTypeUpdate: false,
    };
  },

  computed: {
    ...mapGetters(JOB_TEMPLATE_STORE, [
      'estimateForm',
      'selectedPhaseIndex',
      'templateName',
      'showCreateTemplateConfirmationModal',
      'showSubmitEstimateConfirmationModal',
      'isFormReady',
      'formData',
      'selectDepositModal',
      'showUpdateEstimateConfirmationModal'
    ]),

    ...mapGetters(PROJECT_QUOTES_STORE, [
      'totalFee',
    ]),

    ...mapGetters(PROJECTS_STORE, [
      'currentSelectedProject'
    ]),

    percentLabel() {
      const value = estimateCalculatorHelper.calculateTotalServiceFeePercent(this.estimateForm, this.totalFee);

      return `Service fee (${value}%)`;
    },

    getFormattedDate() {
      if (this.formData.suggestedStartDate !== null) {
        return this.$filters.formatToDate(this.formData.suggestedStartDate);
      }
      return 'No date selected';
    },

    getEstimateDuration() {
      return this.estimateForm?.projectStages.reduce((sum, phase) => sum + this.getDays(phase), 0);
    },
  },

  methods: {
    ...mapActions(JOB_TEMPLATE_STORE, [
      'createMyJobTemplate',
      'updateMyJobTemplate',
      'setShowCreateTemplateConfirmationModal',
      'setShowSubmitEstimateConfirmationModal',
      'setSelectDepositModal',
      'setEstimateForm',
      'setLockTemplateModifiedChecker',
      'setShowUpdateEstimateConfirmationModal'
    ]),
    ...mapActions(PROJECT_QUOTES_STORE, ['updateProjectQuoteStatus']),

    onHandleClick(isSubmitTypeUpdate) {
      if (this.isTemplateCreate) {
        this.setShowCreateTemplateConfirmationModal(true);
      } else {
        this.isSubmitTypeUpdate = isSubmitTypeUpdate;
        this.setSelectDepositModal(true);
      }

      // if (!this.isTemplateCreate && !isSubmitTypeUpdate) {
      //   this.setSelectDepositModal(true);
      // }

      // if (!this.isTemplateCreate && isSubmitTypeUpdate) {
      //   this.setSelectDepositModal(true);
      //   // prompt to update the estimate
      // }
    },

    onCloseConfirmationModal() {
      if (this.isTemplateCreate) {
        this.setShowCreateTemplateConfirmationModal(false);
      } else {
        this.setLockTemplateModifiedChecker(false);
        this.setShowUpdateEstimateConfirmationModal(false);
        this.setShowSubmitEstimateConfirmationModal(false);
      }
    },

    onOpenEstimateConfirmationModal(projectStages) {
      this.setLockTemplateModifiedChecker(true);
      const { projectStages: [form] } = this.estimateForm;
      this.setEstimateForm(Object.assign(form, { projectStages }));
      this.onCloseModals();

      if (this.isSubmitTypeUpdate) {
        this.setShowUpdateEstimateConfirmationModal(true);
      } else {
        this.setShowSubmitEstimateConfirmationModal(true);
      }
    },

    onCloseModals() {
      this.setShowCreateTemplateConfirmationModal(false);
      this.setSelectDepositModal(false);
      this.setShowSubmitEstimateConfirmationModal(false);
      this.setOpenAcceptJobConfirmationModal(false);
      this.setOpenAcceptServiceModal(false);
    },

    async onSaveTemplate() {
      const { params } = this.$route;
      const formData = {
        name: this.templateName,
        meta: this.estimateForm
      };

      this.isSubmitting = true;

      if (params?.id) {
        // update
        await this.updateMyJobTemplate({
          id: params.id,
          formData
        }).then(() => {
          this.$notify.success({
            title: 'Template Updated',
            message: 'Successfully updated.',
          });
          this.$emit('back-to-list');
        }).catch(() => {
          this.$notify.error({
            title: 'Template Creation Failed',
            message: GENERIC_ERROR_MESSAGE,
          });
        })
          .finally(() => {
            this.isSubmitting = false;
            this.onCloseConfirmationModal(false);
          });
      } else {
        this.createMyJobTemplate(formData).then(() => {
          // this.$router.push({ name: 'templates' });
          this.$emit('back-to-list');
        }).catch(() => {
          this.$notify.error({
            title: 'Template Creation Failed',
            message: GENERIC_ERROR_MESSAGE,
          });
        })
          .finally(() => {
            this.isSubmitting = false;
            this.onCloseConfirmationModal(false);
          });
      }
    },

    getDays(phase) {
      const totalDays = phase.projectStageTasks.reduce((sum, { duration }) => sum + duration, 0);
      if (totalDays > 0) {
        return totalDays;
      }
      return phase.totalDuration;
    },

    onSubmitEstimate(saveAsTemplate) {
      this.$emit('on-submit-estimate', saveAsTemplate);
    },

    setOpenAcceptJobConfirmationModal(state) {
      this.confirmJobModal = state;
    },

    setOpenAcceptServiceModal(state) {
      this.serviceAgreementModal = state;
    },

    onOpenAcceptJobConfirmationModal() {
      this.setOpenAcceptJobConfirmationModal(true);
    },

    onAcceptService() {
      this.setOpenAcceptServiceModal(true);
    },

    async submitAcceptJob() {
      this.isLoadingSubmit = true;
      const projectId = this.currentSelectedProject?.id;
      const projectQuoteId = this.currentSelectedProject?.projectQuotes[0].id;
      const formData = {
        projectQuoteStatusId: ProjectQuoteStatusEnum.QUOTE_CONFIRMED,
      };

      if (projectId && projectQuoteId) {
        await this.updateProjectQuoteStatus({ projectId, projectQuoteId, formData })
          .then((response) => {
            if (response) {
              this.$notify.success({
                title: 'Project Quote Accepted.',
                message: 'You successfully accepted the project quote.',
              });
              this.$emit('route-to-overview');
            }
          })
          .catch(() => {
            this.$notify.error({
              title: 'Project Quote Transaction Error.',
              message: 'Error encountered, please try again.',
            });
          })
          .finally(() => {
            this.isLoadingSubmit = false;
            this.onCloseModals();
          });
      }
    },
  },
});
</script>
<style lang="scss" scoped>
  .estimate-summary {
    &__card {
      background: #FFF;
      min-height: 806px;
      max-height: 806px;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0px 0px 20px 0px rgba(12, 15, 74, 0.10);
      padding: 1.5rem;
      box-sizing: border-box;
    }

    &__scrollbar-content {
      padding: .5rem;
    }

    &__text.bold {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 187.5% */
    }

    &__select-state {
      border-radius: 10px;
      border: 1px solid rgba(12, 15, 74, 0.10);
      box-shadow: 0px 0px 10px 0px rgba(42, 42, 42, 0.02);
      padding: 0 1rem;
      box-sizing: border-box;
    }
  }
</style>
