import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import EstimateActions from '@/core/components/common/forms/estimate-form/EstimateActions.vue';
import StatusState from '@/core/components/ui/StatusState.vue';
import { DATE_FORMAT, SYSTEM_DATE_FORMAT } from '@/core/constants';
import NumberHelper from '@/core/helpers/number.helper';
import CurrencyFilter from '@/filters/currency.filter';
import NoteComponent from '@/modules/project-estimate/components/new-components/NoteComponent.vue';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';
export default defineComponent({
    components: {
        EstimateActions,
        StatusState,
        NoteComponent
    },
    props: ['isTemplateCreate', 'isQuoteAccepted'],
    emits: [
        'on-totalize-duration',
        'on-input-phase-cost',
        'on-select-phase-vat'
    ],
    data() {
        return {
            NumberHelper,
            DATE_FORMAT,
            SYSTEM_DATE_FORMAT,
            stageTypeOption: [
                {
                    value: 'labour',
                    label: 'Labour',
                },
                {
                    value: 'materials',
                    label: 'Materials',
                }
            ],
            durationTypeOption: [
                {
                    value: 1,
                    label: 'Days',
                },
            ],
            defaultDurationType: 'Days',
            vatTypeOption: [
                {
                    value: 0,
                    label: '0%',
                },
                {
                    value: 0.05,
                    label: '5%',
                },
                {
                    value: 0.20,
                    label: '20%',
                },
            ],
            disabledPhaseStartDate(date) {
                return moment(date).utc() < moment().startOf('day');
            },
            showNote: false,
            CurrencyFilter,
        };
    },
    created() {
        this.initialize();
    },
    computed: {
        ...mapGetters(JOB_TEMPLATE_STORE, [
            'estimateForm',
            'selectedPhaseIndex',
            'isFormReadOnly'
        ])
    },
    methods: {
        ...mapActions(JOB_TEMPLATE_STORE, ['setSelectedPhaseIndex', 'setShowPhaseUploadAttachmentModal']),
        initialize() {
        },
        isNumber(event, value) {
            return NumberHelper.isNumber(event, value);
        },
        setNoteValue(value, i) {
            this.estimateForm.projectStages[i].note = value;
            this.phaseHasNote(i);
        },
        phaseHasNote(phaseIndex) {
            const phase = this.estimateForm.projectStages[phaseIndex];
            return phase?.note !== null && phase.note !== '';
        },
        setToDecimalFormat(value) {
            return NumberHelper.convertToDecimal(value);
        },
        onPhaseCostBlur(phaseIndex) {
            const subtotalDisplay = this.estimateForm.projectStages[phaseIndex].subTotalDisplay;
            this.estimateForm.projectStages[phaseIndex].subTotal = this.setToDecimalFormat(CurrencyFilter.toNumber(subtotalDisplay));
            this.estimateForm.projectStages[phaseIndex].subTotalDisplay = CurrencyFilter.formatCurrencyWithoutSymbol(subtotalDisplay);
            this.onPhaseCostInput(phaseIndex);
        },
        onCheckUsePhaseTotal(index) {
            this.$emit('on-totalize-duration', index);
        },
        onPhaseCostInput(index) {
            this.$emit('on-input-phase-cost', index);
        },
        onPhaseVatChange(index) {
            this.$emit('on-select-phase-vat', index);
        },
        onDeletePhase() {
            if (!this.isFormReadOnly) {
                this.estimateForm.projectStages = this.estimateForm.projectStages.filter((x, i) => i !== this.selectedPhaseIndex);
                this.setSelectedPhaseIndex(this.estimateForm.projectStages.length - 1);
            }
        },
        onAddAttachment() {
            if (!this.isFormReadOnly) {
                this.setShowPhaseUploadAttachmentModal(true);
            }
        },
        onShowNote() {
            if (!this.isFormReadOnly) {
                this.showNote = !this.showNote;
            }
        }
    }
});
