<template>
  <BaseModal
    :show="show"
    :showCancelBtn="false"
    :showSubmitBtn="false"
    :showDefaultCloseBtn="false"
    @cancel="cancel"
    :hasActions="false"
    >
    <div class="templates flex-column jc-sb">
        <div class="templates__container flex-column">
          <div class="templates__header flex-row ai-c jc-sb">
              <h2 class="templates__title default-text">Templates</h2>
              <inline-svg class="cursor-pointer" @click="cancel"
                :src="require(`@/assets/icons/x.svg`)"></inline-svg>
          </div>
          <div class="templates__body">
            <div class="templates__container flex-column jc-sb">
                <section class="templates__content flex-column">
                  <div class="templates__templates-list flex-column">
                    <span class="template__header default-text">Name</span>
                    <el-radio-group v-model="templateId" @change="onSelect" class="flex-column ai-c">
                        <el-radio :label="template.id" size="large" class="templates__template"
                          v-for="(template, index) in templates" :key="index">
                          <div class="flex-row ai-c">
                            <inline-svg class="templates__icon"
                              :src="require(`@/assets/icons/load-template-icon.svg`)"></inline-svg>
                            <span class="templates__label default-text">{{ template.name }}</span>
                          </div>
                        </el-radio>
                    </el-radio-group>
                  </div>
                </section>
                <section class="templates__actions flex-row ai-c jc-sb">
                 <Button
                   :isTransparent="true"
                   buttonText="cancel"
                   @handle-click="cancel"/>
                 <Button
                   :isActive="isFormReadyToSubmit"
                   :loading="loadingTemplate"
                   buttonText="use template"
                   @handle-click="onLoadTemplate"/>
                </section>
            </div>
          </div>
        </div>
    </div>
  </BaseModal>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
import { DISPLAY_DATE_FORMAT } from '@/core/constants';
import appFilters from '@/filters';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';

export default defineComponent({
  components: {
    BaseModal,
    Button,
  },
  emits: ['close', 'on-load-template'],
  props: ['show'],
  data() {
    return {

      isFormReadyToSubmit: false,
      loading: false,

      defaultForm: null,
      isReadOnly: false,

      templates: [],
      loadingTemplates: false,

      templateId: null,

      loadingTemplate: false,
    };
  },

  watch: {
    templateId: {
      immediate: true,
      handler(newTemplateId) {
        if (newTemplateId !== null) {
          this.isFormReadyToSubmit = true;
        } else {
          this.isFormReadyToSubmit = false;
        }
      },
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters(JOB_TEMPLATE_STORE, [
      'isFormReady',
      'estimateForm',
      'selectedPhaseIndex',
      'defaultProjectStageForm',
      'selectedTemplateId'
    ]),

    selectedPhase() {
      return this.estimateForm.projectStages[this.selectedPhaseIndex];
    },
  },

  methods: {
    ...mapActions(JOB_TEMPLATE_STORE, [
      'getMyJobTemplates',
      'getJobTemplate',
      'setIsFormReady',
      'setEstimateForm',
      'setConfirmLoadTemplateModal',
      'setSelectedTemplateId'
    ]),

    cancel() {
      this.$emit('close');
    },

    async initialize() {
      this.loadingTemplates = true;

      await this.getMyJobTemplates()
        .then((response) => {
          this.templates = response.data.map((item) => {
            return {
              ...item,
              owner: 'Me',
              lastModified: appFilters.formatToDate(item.dateUpdated, DISPLAY_DATE_FORMAT)
            };
          });
        }).catch(() => {})
        .finally(() => {
          this.loadingTemplates = false;
        });
    },

    onSelect() {
      this.setSelectedTemplateId(this.templateId);
    },

    async onLoadTemplate() {
      this.loadingTemplate = true;
      try {
        await this.$emit('on-load-template');
      } finally {
        this.loadingTemplate = false;
      }
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

 .templates {
    min-width: 500px;
    min-height: 700px;
    position: relative;
    height: 700px;

    &__time-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }

    &__container {
      gap: 1.5rem;
      height: 100%;
    }

    &__body {
      height: calc(100% - 3rem);
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
    }

    &__header {
        font-size: 18px;
        font-weight: 700;
        line-height: 25px; /* 138.889% */
        letter-spacing: 0.25px;
    }

    &__content {
      overflow-y: auto;
    }

    &__template {
      width: 100%;
      border-bottom: 1px solid rgba(12, 15, 74, 0.20);
      padding: 1.5rem 0;
      box-sizing: border-box;
      margin: 0;
    }

    &__icon {
      :deep(path) {
        fill: rgba(12, 15, 74, 0.5);
        fill-opacity: 1;
      }
    }

    &__label {
        color: #0C0F4A;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px; /* 157.143% */
        letter-spacing: 0.25px;
    }

    &__actions {
        width: 100%;
        height: auto;
    }
 }

 @include media-sm-max-width() {
   .templates {
    min-height: auto;
    height: auto;
    min-width: 400px;

    &__container {
      gap: 0.5rem;
    }
   }
 }

 @include media-xs-max-width() {
   .templates {
    min-width: 300px;

    &__body {
      height: calc(100vh - 9rem);
    }

    &__content {
      overflow-x: hidden;
    }
    &__label {
      white-space: pre-wrap;
    }
   }
 }
</style>
