<template>
    <div class="invite-form flex-column">
        <div class="invite-form__wrapper flex-column ai-fs">
            <div class="invite-form__input-wrapper flex-row ai-c jc-sb">
                <el-input v-model="email" type="text" :placeholder="inputLabel ? inputLabel : 'Email'"/>
                <el-select class="invite-form__select" v-model="value" filterable placeholder="Role">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
            <div class="invite-form__wrapper flex-column">
                <p class="invite-form__label default-text">Message (optional)</p>
                <el-input style="height: 100%;" type="textarea" v-model="message" clearable autosize></el-input>
            </div>
            <ActionItemComponent icon="send-invite-icon.svg" label="Send Invite" fontColor="#FFA500" :isReverse="true" @call-to-action="addToList"/>
        </div>
        <div class="invite-form__added-container flex-column ai-fs">
            <p class="invite-form__label default-text">Added</p>
            <el-scrollbar>
                <div class="invite-form__wrapper flex-column ai-fs" style="gap: 0;">
                  <AddedMemberItem v-for="(item, index) in initialAdded" :key="index" :added="initialAdded" :options="options" @on-remove="removeFromList"/>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>
<script>
import { ElNotification } from 'element-plus';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import AddedMemberItem from '@/core/components/members/AddedMemberItem.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import { EXISTING_EMAIL_ERROR_MESSAGE, INVALID_EMAIL_ROLE_ERROR_MESSAGE } from '@/core/constants/messages';
import permissionConstant from '@/core/constants/permissions';
import StringHelper from '@/core/helpers/string.helper';
import { USER_WORKSTATION_MEMBERS_STORE } from '@/store/modules/user-workstation-member';

export default defineComponent({
  components: {
    AddedMemberItem,
    ActionItemComponent
  },
  emits: ['update-invite-list'],
  props: ['hasUpdated', 'inputLabel'],
  data: () => {
    return {
      permissionConstant,
      addedValue: null,
      value: null,
      options: [
        {
          value: permissionConstant.ADMIN_LABEL,
          label: permissionConstant.ADMIN_LABEL,
        },
        {
          value: permissionConstant.EDIT_LABEL,
          label: permissionConstant.EDIT_LABEL,
        },
        {
          value: permissionConstant.VIEW_LABEL,
          label: permissionConstant.VIEW_LABEL,
        },
      ],
      email: '',
      message: '',
      initialAdded: [],
    };
  },
  created() {
    this.initialize();
  },

  watch: {
    hasUpdated: {
      immediate: true,
      deep: true,
      handler() {
        this.initialAdded = [];
      }
    }
  },

  computed: {
    ...mapGetters(USER_WORKSTATION_MEMBERS_STORE, ['recentlyAdded'])
  },

  methods: {
    cancel() {
      this.$emit('on-close');
    },

    initialize() {
    },

    addToList() {
      const { value, email } = this;

      if (StringHelper.isValidEmail(email) && value !== null && value !== '' && email !== null && email !== '') {
        const findExistingEmail = this.initialAdded.find((item) => item.email.toLowerCase() === email.toLowerCase());

        if (findExistingEmail) {
          this.$notify.error({
            message: `${EXISTING_EMAIL_ERROR_MESSAGE} ${email}.`
          });
          return;
        }

        this.initialAdded.push(
          {
            email,
            role: value
          }
        );
        this.$emit('update-invite-list', this.initialAdded);
        this.email = '';
        this.value = null;
      } else {
        ElNotification.error({
          message: INVALID_EMAIL_ROLE_ERROR_MESSAGE
        });
      }
    },
    removeFromList(email) {
      this.initialAdded = this.initialAdded.filter((added) => {
        return added.email !== email;
      });
    },
  }
});
</script>
<style lang="scss" scoped>
.invite-form {
  &__label {
    font-size: 15px;
    line-height: 30px;
    font-weight: 600;
  }
  &__wrapper {
    width: 100%;
    gap: .7rem;

    :deep(.el-textarea__inner) {
        height: 100px !important;
    }
  }
  &__added-item {
    width: 100%;
    gap: .5rem;
  }
  &__input-wrapper {
    width: 100%;
    border-bottom: 1px solid rgba(12, 15, 74, 0.20);
  }
  &__added-container {
    height: 150px;
    gap: .5rem;
    .el-scrollbar {
        width: 100%;
    }
  }
}
:deep(.el-input__inner) {
  border: none;
  padding-left: 0;
}
</style>
