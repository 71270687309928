<template>
<BaseModal
  :show="show"
  :showCancelBtn="false"
  :showSubmitBtn="false"
  :showDefaultCloseBtn="false"
  :isLoadingSaveButton="loading"
  :disableSubmitButton="loading"
  @cancel="onCancel"
  :hasActions="false"
>
  <div class="assign-members-modal flex-column jc-sb">
    <section class="assign-members-modal__wrapper flex-column jc-sb">
        <div class="assign-members-modal__modal-header flex-row jc-sb ai-c">
            <h2 class="assign-members-modal__title default-text">Task {{ selectedAssignMembers.taskIndex + 1 }} - assign member</h2>
            <div class="assign-members-modal__header-icon cursor-pointer" @click="onCancel">
              <inline-svg :src="require(`@/assets/icons/x.svg`)"></inline-svg>
            </div>
        </div>
        <div class="assign-members-modal__content flex-column">
            <div class="assign-members-modal__wrapper flex-column jc-sb">
              <el-form label-position="top">
                <el-form-item label="Select a member and assign">
                    <el-select v-model="selectedMemberId" filterable @change="onSelectMember(selectedMemberId)">
                      <el-option
                        v-for="(item, index) in memberList"
                        :key="index"
                        :label="item.toUser.email"
                        :value="item.id"
                      />
                    </el-select>
                </el-form-item>
                <ActionItemComponent
                label="Assign"
                icon="send-invite-icon.svg"
                fontColor="#FFA500"
                fontSize="12px"
                :isReverse="true"
                @call-to-action="addToList"/>
              </el-form>
              <el-scrollbar class="assign-members-modal__email-lists-container">
                <div class="assign-members-modal__scrollbar-content flex-column"
                  v-if="initialAdded.selectedMembers.length">
                    <div class="assign-members-modal__invited-item flex-row ai-c jc-sb"
                      v-for="(added, index) in initialAdded.selectedMembers"
                      :key="index">
                      <p class="default-text">{{added?.email}}</p>
                      <div class="flex-row ai-c">
                        <!-- we don't need role for this now -->
                        <el-select
                          style="visibility: hidden;"
                          class="assign-members-modal__role"
                          v-model="added.role" filterable placeholder="Role"
                          @change="onUpdate(scope.row.id, added.role)">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                        <inline-svg
                          class="cursor-pointer"
                          :src="require(`@/assets/icons/trash-icon.svg`)"
                          id="remove"
                          @click="removeFromList(added?.id)">
                        </inline-svg>
                      </div>
                    </div>
                </div>
                <p class="default-text" v-else>No assign members</p>
              </el-scrollbar>
            </div>
        </div>
    </section>
    <section class="assign-members-modal__actions grid">
      <Button :isActive="initialAdded.selectedMembers.length" buttonText="save" @handle-click="onSave"/>
    </section>
  </div>
</BaseModal>
</template>
<script>
// import { ProjectStageTypeEnum } from '@/core/packages/shared-library';
import { defineComponent } from '@vue/runtime-core';
import { ElNotification } from 'element-plus';

import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
import { EXISTING_EMAIL_ERROR_MESSAGE, NO_MEMBER_SELECTED } from '@/core/constants/messages';
import permissionConstant from '@/core/constants/permissions';

export default defineComponent({
  name: 'assign-members',
  props: ['show', 'memberList', 'assignMembers', 'selectedAssignMembers'],
  emits: ['close', 'on-save'],
  components: {
    BaseModal,
    Button,
    ActionItemComponent,
  },

  data() {
    return {
      permissionConstant,
      selectedMemberId: null,
      selectedMemberDetail: null,
      email: '',
      initialAdded: {
        phaseIndex: null,
        taskIndex: null,
        selectedMembers: []
      },
      options: [
        {
          value: permissionConstant.EDIT_LABEL,
          label: permissionConstant.EDIT_LABEL,
        },
        {
          value: permissionConstant.VIEW_LABEL,
          label: permissionConstant.VIEW_LABEL,
        },
      ],

      // for testing
      // memberList: [
      //   {
      //     toUser: {
      //       email: 'jasper@iknowa.com'
      //     },
      //     toUserId: 2,
      //     id: 1
      //   },
      //   {
      //     toUser: {
      //       email: 'phil@iknowa.com'
      //     },
      //     toUserId: 4,
      //     id: 2
      //   },
      //   {
      //     toUser: {
      //       email: 'keano@iknowa.com'
      //     },
      //     toUserId: 6,
      //     id: 3
      //   },
      //   {
      //     toUser: {
      //       email: 'allar@iknowa.com'
      //     },
      //     toUserId: 7,
      //     id: 4
      //   }
      // ]
    };
  },

  created() {
    this.setMembersAssigned();
  },

  computed: {
  },

  methods: {
    onCancel() {
      this.$emit('close');
    },

    setMembersAssigned() {
      const { phaseIndex, taskIndex } = this.selectedAssignMembers;
      const { assignMembers } = this;

      let foundAssignMembers = assignMembers.find(
        (item) => item.phaseIndex === phaseIndex && item.taskIndex === taskIndex
      );

      if (typeof foundAssignMembers === 'undefined' || foundAssignMembers === null) {
        foundAssignMembers = {
          phaseIndex,
          taskIndex,
          selectedMembers: []
        }; // Set it to an empty array
      }

      this.initialAdded = foundAssignMembers;
    },

    addToList() {
      if (this.selectedMemberId && this.selectedMemberId !== null) {
        const findExistingId = this.initialAdded.selectedMembers.find((item) => item.id === this.selectedMemberId);
        if (findExistingId) {
          this.$notify.error({
            message: `${EXISTING_EMAIL_ERROR_MESSAGE}`
          });
          return;
        }

        this.initialAdded.selectedMembers.push(this.selectedMemberDetail);

        // this.selectedMember = null;
        this.selectedMemberDetail = null;
        this.selectedMemberId = null;
      } else {
        ElNotification.error({
          message: NO_MEMBER_SELECTED
        });
      }
    },

    removeFromList(id) {
      this.initialAdded.selectedMembers = this.initialAdded.selectedMembers.filter((added) => {
        return added.id !== id;
      });
    },

    onSave() {
      const { phaseIndex, taskIndex } = this.selectedAssignMembers;

      this.initialAdded.phaseIndex = phaseIndex;
      this.initialAdded.taskIndex = taskIndex;

      this.$emit('on-save', this.initialAdded);
      this.initialAdded = {
        phaseIndex: null,
        taskIndex: null,
        selectedMembers: []
      };
    },

    onSelectMember(id) {
      const member = this.memberList.find((user) => user.id === id);
      this.selectedMemberDetail = {
        user: { id: member.toUserId },
        wsMember: { id: member.id },
        email: member.toUser.email
      };

      // this.selectedMemberDetail.role = permissionConstant.VIEW_LABEL;
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

.assign-members-modal {
  max-width: 500px;
  min-width: 500px;
  min-height: 590px;

  &__scrollbar-content {
    gap: 0;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
  }
  &__content {
    .el-select {
      width: 100%;
    }
  }
  &__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
  }
  &__actions {
    width: 100%;
    grid-template-columns: 1fr;
  }
  &__role {
    :deep(.el-input__inner) {
      border: none;
      outline: none;
      background: transparent;
      padding: 0;
      width: 70px;
    }
  }
}
:deep(.el-input__inner) {
  border-radius: 100px;
  border: none;
  background-color: rgba(12, 15, 74, 0.05);
}
.el-divider {
  margin: 0;
}

@include media-xs-max-width() {
  .assign-members-modal {
    width: 100%;
    min-height: 400px;
  }
}
</style>
