import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import EstimateActions from '@/core/components/common/forms/estimate-form/EstimateActions.vue';
// import StatusState from '@/core/components/ui/StatusState.vue';
import { DATE_FORMAT, SYSTEM_DATE_FORMAT } from '@/core/constants';
import NumberHelper from '@/core/helpers/number.helper';
import CurrencyFilter from '@/filters/currency.filter';
import NoteComponent from '@/modules/project-estimate/components/new-components/NoteComponent.vue';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';
export default defineComponent({
    components: {
        EstimateActions,
        // StatusState,
        NoteComponent
    },
    props: ['index', 'isTemplateCreate'],
    data() {
        return {
            DATE_FORMAT,
            SYSTEM_DATE_FORMAT,
            durationTypeOption: [
                {
                    value: 1,
                    label: 'Days',
                },
            ],
            defaultDurationType: 'Days',
            isFormReadOnly: false,
            disabledProjectStartDate(date) {
                return moment(date).utc() < moment().startOf('day');
            },
            showNote: false,
            CurrencyFilter
        };
    },
    watch: {
        showNote: {
            immediate: true,
            deep: true,
            handler(value) {
                if (!value) {
                    this.setNoteValue(null, this.index);
                }
            }
        }
    },
    computed: {
        ...mapGetters(JOB_TEMPLATE_STORE, ['estimateForm', 'selectedPhaseIndex']),
        selectedPhase() {
            return this.estimateForm.projectStages[this.selectedPhaseIndex];
        }
    },
    methods: {
        ...mapActions(JOB_TEMPLATE_STORE, ['setShowUploadAttachmentModal']),
        isNumber(event, value) {
            return NumberHelper.isNumber(event, value);
        },
        setToDecimalFormat(value) {
            return NumberHelper.convertToDecimal(value);
        },
        onTaskCostBlur(phaseIndex, taskIndex) {
            const amountToDisplay = this.estimateForm.projectStages[phaseIndex].projectStageTasks[taskIndex]?.amountToDisplay;
            this.estimateForm.projectStages[phaseIndex].projectStageTasks[taskIndex].amount = this.setToDecimalFormat(CurrencyFilter.toNumber(amountToDisplay));
            this.estimateForm.projectStages[phaseIndex].projectStageTasks[taskIndex].amountToDisplay = CurrencyFilter.formatCurrencyWithoutSymbol(amountToDisplay);
            this.onTaskCostInput(phaseIndex);
        },
        setNoteValue(value, index) {
            this.selectedPhase.projectStageTasks[index].note = value;
        },
        onTaskCostInput(index) {
            this.$emit('on-input-task-cost', index);
        },
        onDeleteItem() {
            const selectedPhase = this.estimateForm.projectStages[this.selectedPhaseIndex];
            selectedPhase.projectStageTasks = selectedPhase.projectStageTasks.filter((x, xi) => xi !== this.index);
        },
        onAddAttachment() {
            this.setShowUploadAttachmentModal(true);
        }
    }
});
