<template>
<BaseModal
  :show="show"
  :showCancelBtn="false"
  :showSubmitBtn="false"
  :showDefaultCloseBtn="false"
  @cancel="cancel"
  :hasActions="false"
  customClass="xs-modal"
>
  <div class="modal-content flex-column jc-sb">
    <div class="flex-column jc-sb">
        <div class="modal-content__modal-header flex-row jc-fe ai-c">
            <div class="modal-content__header-icon cursor-pointer">
              <inline-svg class="cursor-pointer" @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
            </div>
        </div>
        <div class="modal-content__content flex-column ai-c gap-2">
          <inline-svg :src="require(`@/assets/icons/template-icon-2.svg`)"></inline-svg>
          <p class="modal-content__title default-text">Are you sure you want to load the template? This action will overwrite your current data.</p>
        </div>
    </div>
    <div class="modal-content__actions grid">
      <Button
        :isActive="true"
        activeColor="rgba(12, 15, 74, 0.05)"
        @handle-click="cancel"
        activeFontColor="rgba(12, 15, 74, 0.80)"
        buttonText="no"/>
      <Button @handle-click="onLoad" :isActive="true" buttonText="yes"/>
    </div>
  </div>
</BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';

import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';

export default defineComponent({
  name: 'confirm-create-template-modal',
  emits: ['close', 'on-load'],
  components: {
    BaseModal,
    Button
  },
  data() {
    return {
    };
  },

  props: {
    show: Boolean,
  },

  computed: {
  },

  methods: {
    cancel() {
      this.$emit('close');
    },

    onLoad() {
      this.$emit('on-load');
    }
  }
});
</script>
<style lang="scss" scoped>
.default-text {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.15000000596046448px;
    margin: 0;
    color: #0C0F4A;
}
.modal-content {
  min-height: 350px;
  height: 100%;
  max-width: 330px;

  &__title {
    font-size: 24px;
    text-align: center;
  }
  &__input-container {
    gap: .1rem;
  }
  &__input-name {
    font-size: 12px;
    line-height: 22px;
  }
  &__text-description{
    font-size: 12px;
    font-weight: 500;
    color: rgba(12, 15, 74, 0.5);
  }
  &__actions {
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
}
:deep(.el-input__inner) {
  border-radius: 8px;
}
</style>
