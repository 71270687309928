import { ProjectStageTypeEnum } from '@/core/packages/shared-library';
export default {
    getPhaseText(estimateForm, stageType, index) {
        let materialsCount = 0;
        let labourCount = 0;
        for (let i = 0; i <= index; i++) {
            if (estimateForm.projectStages[i].stageType === ProjectStageTypeEnum.MATERIALS) {
                materialsCount += 1;
            }
            else if (estimateForm.projectStages[i].stageType === ProjectStageTypeEnum.LABOUR) {
                labourCount += 1;
            }
        }
        if (stageType === ProjectStageTypeEnum.MATERIALS) {
            return `Materials ${materialsCount}`;
        }
        return `Phase ${labourCount}`;
    }
};
