<template>
    <div class="status-state flex-row ai-c jc-c">
        <p class="status-state__text default-text">{{status}}</p>
    </div>
</template>
<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: '#D0AD52'
    },
    status: {
      type: String,
      Default: 'true'
    },
    fontColor: {
      type: String,
      default: '#FFF'
    },
    fontSize: {
      type: String,
      default: '14px'
    },
    boxShadow: {
      type: String,
      default: '0px 0px 20px 0px rgba(12, 15, 74, 0.10)'
    },
    border: {
      type: String,
      default: 'none'
    },
    padding: {
      type: String,
      default: '.3rem 1.1rem'
    }
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.status-state {
    background-color: v-bind('$props.bgColor');
    padding: v-bind('$props.padding');
    box-sizing: border-box;
    border: v-bind('$props.border');
    border-radius: 8px;
    box-shadow: v-bind('$props.boxShadow');
    min-width: 64px;

    &__text {
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0.23999999463558197px;
      color: v-bind('$props.fontColor');
      text-align: center;
      white-space: nowrap;
    }
}

@media screen and (max-width: 1666px) {
  .status-state {
    &__text {
      font-size: 10px;
    }
  }
}
</style>
