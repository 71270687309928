import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import EstimateActions from '@/core/components/common/forms/estimate-form/EstimateActions.vue';
// import StatusState from '@/core/components/ui/StatusState.vue';
import { DATE_FORMAT, SYSTEM_DATE_FORMAT } from '@/core/constants';
import NumberHelper from '@/core/helpers/number.helper';
import CurrencyFilter from '@/filters/currency.filter';
import NoteComponent from '@/modules/project-estimate/components/new-components/NoteComponent.vue';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';
export default defineComponent({
    components: {
        EstimateActions,
        // StatusState,
        NoteComponent
    },
    props: ['index', 'isTemplateCreate', 'isQuoteAccepted', 'task'],
    emits: [
        'on-input-task-cost'
    ],
    data() {
        return {
            DATE_FORMAT,
            SYSTEM_DATE_FORMAT,
            durationTypeOption: [
                {
                    value: 1,
                    label: 'Days',
                },
            ],
            defaultDurationType: 'Days',
            disabledProjectStartDate(date) {
                return moment(date).utc() < moment().startOf('day');
            },
            showNote: false,
            CurrencyFilter
        };
    },
    computed: {
        ...mapGetters(JOB_TEMPLATE_STORE, [
            'estimateForm',
            'selectedPhaseIndex',
            'isFormReadOnly',
            'assignMembers'
        ]),
        selectedPhase() {
            return this.estimateForm.projectStages[this.selectedPhaseIndex];
        }
    },
    methods: {
        ...mapActions(JOB_TEMPLATE_STORE, [
            'setShowTaskUploadAttachmentModal',
            'setSelectedAssignMembers',
            'setAssignMembersModal'
        ]),
        setNoteValue(value, index) {
            this.selectedPhase.projectStageTasks[index].note = value;
            this.taskHasNote(index);
        },
        taskHasNote(taskIndex) {
            const task = this.estimateForm.projectStages[this.selectedPhaseIndex].projectStageTasks[taskIndex];
            return task?.note !== null && task.note !== '';
        },
        isNumber(event, value) {
            return NumberHelper.isNumber(event, value);
        },
        setToDecimalFormat(value) {
            return NumberHelper.convertToDecimal(value);
        },
        onTaskCostBlur(phaseIndex, taskIndex) {
            const amountToDisplay = this.estimateForm.projectStages[phaseIndex].projectStageTasks[taskIndex]?.amountToDisplay;
            this.estimateForm.projectStages[phaseIndex].projectStageTasks[taskIndex].amount = this.setToDecimalFormat(CurrencyFilter.toNumber(amountToDisplay));
            this.estimateForm.projectStages[phaseIndex].projectStageTasks[taskIndex].amountToDisplay = CurrencyFilter.formatCurrencyWithoutSymbol(amountToDisplay);
            this.onTaskCostInput(phaseIndex);
        },
        onTaskCostInput(index) {
            this.$emit('on-input-task-cost', index);
        },
        onInputTaskDuration(i) {
            const totalDuration = this.estimateForm.projectStages[i].projectStageTasks.reduce((sum, x) => sum + x.duration, 0);
            this.estimateForm.projectStages[i].totalDuration = totalDuration;
        },
        onDeleteTask(i, ii) {
            if (!this.isFormReadOnly) {
                // Delete the task from estimateForm.projectStages
                this.estimateForm.projectStages[i].projectStageTasks = this.estimateForm.projectStages[i].projectStageTasks.filter((x, xi) => xi !== ii);
                // Find and delete the corresponding assignMembers entry
                const assignMembersIndex = this.assignMembers.findIndex((item) => item.phaseIndex === i && item.taskIndex === ii);
                if (assignMembersIndex !== -1) {
                    this.assignMembers.splice(assignMembersIndex, 1);
                }
                // Update the indices of the remaining assignMembers entries
                for (let idx = 0; idx < this.assignMembers.length; idx++) {
                    const item = this.assignMembers[idx];
                    if (item.phaseIndex === i && item.taskIndex > ii) {
                        const decrementedTaskIndex = item.taskIndex - 1;
                        item.taskIndex = decrementedTaskIndex;
                    }
                }
            }
            this.onTaskCostInput(i);
        },
        onAddAttachment() {
            if (!this.isFormReadOnly) {
                this.setShowTaskUploadAttachmentModal(true);
            }
        },
        onShowNote() {
            if (!this.isFormReadOnly) {
                this.showNote = !this.showNote;
            }
        },
        onOpenAssignMembersModal(phaseIndex, taskIndex) {
            this.setSelectedAssignMembers({
                phaseIndex,
                taskIndex
            });
            this.setAssignMembersModal(true);
        },
    }
});
