<template>
    <CollapsibleSlot class="phase-summary" borderRadius="10px" boxShadow="0px 0px 5px 0px rgba(12, 15, 74, 0.20)" headerPadding="1rem .8rem 1rem 1.3rem" accordion>
      <template #header-one>
        <div class="flex-row ai-c jc-sb">
            <div class="phase-summary__wrapper flex-row ai-c gap-half">
              <StatusState
                v-if="isLabour"
                :status="estimatePhaseHelper.getPhaseText(estimateForm, data?.stageType, index)"
                boxShadow="none" fontColor="#4A92E5" bgColor="rgba(74, 146, 229, 0.1)" style="min-width: 70px;"/>
              <p class="phase-summary__text default-text" :class="{'darkBlue': isMaterial }">
                {{ isMaterial ? 'Materials' : data?.name }}
              </p>
            </div>
            <div class="phase-summary__wrapper flex-row ai-c gap-half">
               <DataWithLabel :noSpaceBetween="true" :label="isLabour ? 'Tasks' : 'Item'"
                 :data="data?.projectStageTasks && data?.projectStageTasks.length" :rowReverse="true" :dataFontBold="true" :gapHalf="true"/>
               <el-divider direction="vertical"/>
               <DataWithLabel v-if="isLabour" :noSpaceBetween="true" label="Days"
                 :data="getDays(data)" :rowReverse="true" :dataFontBold="true" :gapHalf="true"/>
               <el-divider direction="vertical"/>
               <DataWithLabel :noSpaceBetween="true" label="Cost"
                 :data="isTradesperson ? $filters.formatToCurrency(data?.stageCost || data?.subTotal) : $filters.formatToCurrency(data?.stageCostWithFee)" :rowReverse="true" :dataFontBold="true" :gapHalf="true"/>
            </div>
        </div>
      </template>
      <template #content v-if="data?.projectStageTasks && data?.projectStageTasks.length">
        <div class="phase-summary__content flex-column">
          <ProjectTaskSummaryItem v-for="(task, i) in data.projectStageTasks" :key="i"
            :task="task" :index="i+1" :isMaterial="isMaterial"/>
        </div>
      </template>
      <template #empty-state v-else>
        <p class="default-text">No Tasks added</p>
      </template>
    </CollapsibleSlot>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import ProjectTaskSummaryItem from '@/core/components/jobs/estimates/ProjectTaskSummaryItem.vue';
import CollapsibleSlot from '@/core/components/slots/CollapsibleSlot.vue';
import DataWithLabel from '@/core/components/ui/DataWithLabel.vue';
import StatusState from '@/core/components/ui/StatusState.vue';
import estimatePhaseHelper from '@/core/helpers/estimate-phase.helper';
import { ProjectStageTypeEnum } from '@/core/packages/shared-library';
import CurrencyFilter from '@/filters/currency.filter';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';

export default defineComponent({
  components: {
    CollapsibleSlot,
    StatusState,
    DataWithLabel,
    ProjectTaskSummaryItem
  },

  props: ['data', 'index'],

  data() {
    return {
      ProjectStageTypeEnum,
      count: 3,
      estimatePhaseHelper
    };
  },

  computed: {
    ...mapGetters(['isTradesperson']),
    ...mapGetters(JOB_TEMPLATE_STORE, [
      'estimateForm',
    ]),

    isMaterial() {
      return this.data?.stageType === ProjectStageTypeEnum.MATERIALS;
    },

    isLabour() {
      return this.data?.stageType === ProjectStageTypeEnum.LABOUR;
    }
  },

  methods: {
    getDays(phase) {
      const totalDays = phase.projectStageTasks.reduce((sum, { duration }) => sum + duration, 0);
      if (totalDays > 0) {
        return totalDays;
      }

      return phase.totalDuration || 0;
    },

    getTotalCost(phase) {
      if (phase.stageType === ProjectStageTypeEnum.MATERIALS) {
        const newSubtotal = 0;
        if (typeof phase.subTotal === 'undefined') {
          return newSubtotal;
        }
        return CurrencyFilter.formatToCurrency(phase.subTotal.toString());
      }
      const totalEarning = phase.projectStageTasks.reduce((sum, { total }) => sum + total, 0);
      if (totalEarning > 0) {
        return CurrencyFilter.formatToCurrency(totalEarning);
      }
      if (phase?.totalCostWithVat === undefined) {
        return CurrencyFilter.formatToCurrency(phase.stageCost.toString());
      }
      return phase?.totalCostWithVat;
    },
  },
});
</script>
<style lang="scss" scoped>
.phase-summary {
  &__text {
    color: #4A92E5;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px; /* 208.333% */
    letter-spacing: 0.25px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%;
    line-height: 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  &__text.darkBlue {
    color: #0C0F4A;
    width: 100%;
  }
}
</style>
