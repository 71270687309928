import { defineComponent } from 'vue';
import DefaultDynamicStatusModalContent from '@/core/components/common/modals/DefaultDynamicStatusModalContent.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
export default defineComponent({
    name: 'confirm-job-modal',
    emits: ['on-confirm', 'on-cancel'],
    components: {
        DefaultDynamicStatusModalContent,
        BaseModal
    },
    data() {
        return {
            type: 'confirmJob',
            content: {
                hasTwoButton: true,
                /* eslint-disable global-require */
                icon: `${require('@/assets/icons/confirm-estimate-icon.svg')}`,
                /* eslint-disable global-require */
                statusText: 'Please comfirm that you are happy to accept this job',
                hasDescription: true,
                descriptionText: 'By accepting the estimate you will agree with the terms within the',
                spanText: 'Service Agreement',
                buttonActiveLabel: 'confirm',
            }
        };
    },
    methods: {
        onConfirm() {
            this.$emit('on-confirm');
        },
        onCancel() {
            this.$emit('on-cancel');
        }
    }
});
