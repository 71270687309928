import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import EstimateHeaderForm from '@/core/components/common/forms/estimate-form/EstimateHeaderForm.vue';
import GlobalDynamicHeader from '@/core/components/common/header/GlobalDynamicHeader.vue';
import AssignMembersModal from '@/core/components/common/modals/AssignMembersModal.vue';
import EstimatePhaseAttachmentUploadModal from '@/core/components/common/modals/EstimatePhaseAttachmentUploadModal.vue';
import EstimateTaskAttachmentUploadModal from '@/core/components/common/modals/EstimateTaskAttachmentUploadModal.vue';
import OverrideEstimateConfirmationModal from '@/core/components/common/modals/OverrideEstimateConfirmationModal.vue';
import TemplatesListModal from '@/core/components/common/modals/TemplatesListModal.vue';
import LabourForm from '@/core/components/jobs/estimates/Labour.vue';
import MaterialForm from '@/core/components/jobs/estimates/Materials.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import { ProjectStageTypeEnum } from '@/core/packages/shared-library';
import CurrencyFilter from '@/filters/currency.filter';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';
import { PROJECTS_STORE } from '@/store/modules/projects';
export default defineComponent({
    components: {
        GlobalDynamicHeader,
        ActionItemComponent,
        EstimateHeaderForm,
        LabourForm,
        MaterialForm,
        EstimatePhaseAttachmentUploadModal,
        TemplatesListModal,
        OverrideEstimateConfirmationModal,
        AssignMembersModal,
        EstimateTaskAttachmentUploadModal
    },
    emits: ['on-calculate'],
    props: {
        showHeader: {
            type: Boolean,
            default: true,
        },
        isTemplateCreate: {
            type: Boolean,
            default: false,
        },
        canQuoteSubmit: {
            type: Boolean,
            default: false
        },
        projectQuote: {
            type: Object,
            default: null,
        },
        createdQuoteId: {
            default: null
        },
        isQuoteAccepted: {
            type: Boolean,
        }
    },
    data() {
        return {
            ProjectStageTypeEnum,
            showTemplateListModal: false,
            estimate: null,
            CurrencyFilter
        };
    },
    watch: {
        estimateForm: {
            deep: true,
            immediate: true,
            handler(value) {
                if (value?.projectStages) {
                    this.setIsFormReady(this.formValidationChecker());
                    if (this.estimate && this.estimate !== null && !this.lockTemplateModifiedChecker) {
                        this.isLoadedTemplateModified();
                    }
                }
            },
        },
        formData: {
            deep: true,
            immediate: true,
            handler(value) {
                if (value?.projectStages) {
                    this.setIsFormReady(this.formValidationChecker());
                }
            },
        },
    },
    computed: {
        ...mapGetters(JOB_TEMPLATE_STORE, [
            'estimateForm',
            'selectedPhaseIndex',
            'showPhaseUploadAttachmentModal',
            'showTaskUploadAttachmentModal',
            'confirmLoadTemplateModal',
            'selectedTemplateId',
            'defaultProjectStageForm',
            'formData',
            'isEstimateLoadedModified',
            'lockTemplateModifiedChecker',
            'assignMembersModal',
            'memberList',
            'assignMembers',
            'selectedAssignMembers',
            'isFormReadOnly',
            'templateName'
        ]),
        ...mapGetters(PROJECT_QUOTES_STORE, [
            'totalFee',
        ]),
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        ...mapGetters(PROJECTS_STORE, [
            'currentSelectedProject'
        ]),
        selectedPhase() {
            return this.estimateForm.projectStages[this.selectedPhaseIndex];
        },
        hasEstimate() {
            const { estimate } = this;
            return estimate && estimate !== null;
        },
        estimateLabel() {
            const cmp = this;
            if (this.isTemplateCreate) {
                const name = cmp.templateName;
                return name ? `<strong>Name: ${name}</strong>` : 'Estimate template';
            }
            const name = cmp.estimate?.name;
            return name ? `<strong>Loaded template:</strong> ${name}` : '';
        },
        isUpdate() {
            return this.currentSelectedProject?.projectQuotes?.length && this.canQuoteSubmit;
        },
    },
    methods: {
        ...mapActions(JOB_TEMPLATE_STORE, [
            'setShowPhaseUploadAttachmentModal',
            'setConfirmLoadTemplateModal',
            'setSelectedTemplateId',
            'setEstimateForm',
            'getJobTemplate',
            'setIsFormReady',
            'setIsEstimateLoadedModified',
            'setHasLoadedTemplate',
            'setLoadedEstimate',
            'setAssignMembersModal',
            'setSelectedAssignMembers',
            'setMemberList',
            'setAssignMembers',
            'setShowTaskUploadAttachmentModal'
        ]),
        ...mapActions(PROJECT_QUOTES_STORE, [
            'calculateServiceFee',
            'getTotalStageCost',
            'setTotalFee'
        ]),
        getTotalVat(phase) {
            if (phase.vat === undefined) {
                return 0;
            }
            const totalVatAmount = phase.projectStageTasks.reduce((sum, x) => sum + Number(parseFloat(x.stageCost)) * Number(x.vat), 0);
            if (totalVatAmount > 0) {
                return totalVatAmount;
            }
            return Number(parseFloat(phase.stageCost)) * Number(phase.vat);
        },
        onCheckUsePhaseTotal(i) {
            const cmp = this;
            const phase = cmp.estimateForm.projectStages[i] || null;
            if (phase.stageType === ProjectStageTypeEnum.LABOUR) {
                const isFilledPhaseInput = Number(phase.totalDuration) > 0 || Number(phase.stageCost) > 0;
                const isFilledTaskInput = phase.projectStageTasks.some((x) => {
                    return Number(x.amount) > 0 || Number(x.duration) > 0;
                });
                setTimeout(() => {
                    if (isFilledPhaseInput === false && isFilledTaskInput === false) {
                        cmp.estimateForm.projectStages[i].isUsePhaseTotal = null;
                    }
                    else {
                        cmp.estimateForm.projectStages[i].isUsePhaseTotal = !isFilledTaskInput;
                    }
                });
            }
            else if (phase.stageType === ProjectStageTypeEnum.MATERIALS) {
                const isFilledPhaseInput = Number(phase.stageCost) > 0;
                const isFilledItemInput = phase.projectStageTasks.some((x) => {
                    return Number(x.amount) > 0 || Number(x.quantity) > 0;
                });
                setTimeout(() => {
                    if (isFilledPhaseInput === false && isFilledItemInput === false) {
                        cmp.estimateForm.projectStages[i].isUsePhaseTotal = null;
                    }
                    else {
                        cmp.estimateForm.projectStages[i].isUsePhaseTotal = !isFilledItemInput;
                    }
                });
            }
        },
        onPhaseCostInput(phaseIndex) {
            const cmp = this;
            cmp.updatePhaseCost(phaseIndex);
            cmp.onCheckUsePhaseTotal(phaseIndex);
        },
        onPhaseVatChange(phaseIndex) {
            this.updatePhaseCost(phaseIndex);
        },
        onTaskCostInput(phaseIndex) {
            const cmp = this;
            cmp.updatePhaseSubTotal(phaseIndex);
            cmp.updatePhaseCost(phaseIndex);
            cmp.onCheckUsePhaseTotal(phaseIndex);
        },
        updatePhaseCost(phaseId) {
            const cmp = this;
            const currentPhase = cmp.estimateForm.projectStages[phaseId] || null;
            if (currentPhase) {
                currentPhase.stageCost = cmp.getPhaseTotalCostWithVat(phaseId);
            }
            cmp.$emit('on-calculate');
        },
        updatePhaseSubTotal(phaseId) {
            const cmp = this;
            const currentPhase = cmp.estimateForm.projectStages[phaseId] || null;
            if (currentPhase) {
                currentPhase.subTotal = cmp.getPhaseSubTotal(phaseId);
                currentPhase.subTotalDisplay = CurrencyFilter.formatCurrencyWithoutSymbol(cmp.getPhaseSubTotal(phaseId));
            }
        },
        getPhaseTotalCostWithVat(phaseIndex) {
            const cmp = this;
            const currentPhase = cmp.estimateForm.projectStages[phaseIndex];
            let total = 0;
            const stageSubTotal = parseFloat(currentPhase.subTotal);
            const vat = parseFloat(currentPhase.vat);
            const vatAmount = (vat * stageSubTotal);
            total = total + stageSubTotal + vatAmount;
            return Number.isNaN(parseFloat(total.toString())) ? 0 : total;
        },
        getPhaseSubTotal(phaseIndex) {
            let total = 0;
            const cmp = this;
            const currentPhase = cmp.estimateForm.projectStages[phaseIndex];
            if (currentPhase.projectStageTasks.length) {
                currentPhase.projectStageTasks.forEach((task) => {
                    const amount = parseFloat(task.amount);
                    total += !Number.isNaN(amount) ? amount : 0;
                });
            }
            return total.toFixed(2);
        },
        onClosePhaseAttachmentModal() {
            this.setShowPhaseUploadAttachmentModal(false);
        },
        onCloseTaskAttachmentModal() {
            this.setShowTaskUploadAttachmentModal(false);
        },
        setTemplateListModal(state) {
            this.showTemplateListModal = state;
        },
        onOpenTemplateListModal() {
            if (!this.isFormReadOnly) {
                this.setTemplateListModal(true);
            }
        },
        onCloseTemplateListModal() {
            this.setTemplateListModal(false);
        },
        setLocalLoadedEstimate(estimate) {
            this.estimate = estimate;
        },
        onCloseConfirmLoadTemplateModal() {
            this.setConfirmLoadTemplateModal(false);
        },
        isFormNotEmpty() {
            const estimateForm = this.estimateForm.projectStages;
            for (let i = 0; i < estimateForm.length; i++) {
                const stageA = estimateForm[i];
                if (stageA.name !== ''
                    || stageA.totalDuration !== 0
                    || stageA.stageCost !== 0
                    || stageA.vat !== 0
                    || stageA.subTotal !== 0
                    // || stageA.note !== null
                    || stageA.projectStageTasks.length > 0) {
                    return true;
                }
            }
            return false;
        },
        formValidationChecker() {
            const estimateForm = this.estimateForm.projectStages;
            for (let i = 0; i < estimateForm.length; i++) {
                const stageA = estimateForm[i];
                if ((stageA.name === ''
                    || stageA.totalDuration === 0
                    || stageA.stageCost === 0
                    || stageA.vat === 0
                    || stageA.subTotal === 0)
                    && this.isTemplateCreate
                    && stageA.stageType === ProjectStageTypeEnum.LABOUR) {
                    return false;
                }
                if ((stageA.name === ''
                    || stageA.totalDuration === 0
                    || stageA.stageCost === 0
                    || stageA.vat === 0
                    || stageA.subTotal === 0
                    || stageA.startDate === null
                    || this.formData.suggestedStartDate === null)
                    && !this.isTemplateCreate
                    && stageA.stageType === ProjectStageTypeEnum.LABOUR) {
                    return false;
                }
                if ((stageA.subTotal === null)
                    && !this.isTemplateCreate
                    && stageA.stageType === ProjectStageTypeEnum.MATERIALS) {
                    return false;
                }
            }
            return true;
        },
        async onLoadTemplate() {
            if (this.estimateForm.projectStages) {
                if (this.isFormNotEmpty()) {
                    this.onCloseTemplateListModal();
                    this.setConfirmLoadTemplateModal(true);
                }
                else {
                    this.loadTemplate();
                }
            }
        },
        async loadTemplate() {
            let { selectedTemplateId } = this;
            if (selectedTemplateId !== null) {
                await this.getJobTemplate(selectedTemplateId)
                    .then((response) => {
                    // add startDate: null to each response.meta data.
                    response.meta.projectStages.forEach((stage) => {
                        stage.startDate = null;
                        stage.subTotalDisplay = CurrencyFilter.formatCurrencyWithoutSymbol(stage.subTotal);
                        stage.projectStageTasks.forEach((task) => {
                            task.amount = CurrencyFilter.toNumber(task.amount);
                            task.amountToDisplay = CurrencyFilter.formatCurrencyWithoutSymbol(task.amount);
                        });
                    });
                    this.setEstimateForm(response?.meta);
                    this.setLocalLoadedEstimate(response);
                    this.setLoadedEstimate(response);
                    this.setHasLoadedTemplate(true);
                    this.$emit('on-calculate');
                    this.$notify.success({
                        title: 'Successful',
                        message: 'Template successfully loaded',
                    });
                    this.onCloseTemplateListModal();
                    selectedTemplateId = null;
                    // this.setIsFormReady(true);
                })
                    .catch(() => {
                })
                    .finally(() => {
                    this.setConfirmLoadTemplateModal(false);
                });
            }
        },
        isLoadedTemplateModified() {
            const cmp = this;
            const pickKeys = [
                'name',
                'projectStageTasks',
                'stageCost',
                'stageType',
                'totalDuration',
                'vat',
                'subTotal',
            ];
            const isModified = cmp.estimate.meta.projectStages.some((stage) => {
                const estimateFormStage = this.estimateForm.projectStages.find((formStage) => formStage.name === stage.name);
                if (!estimateFormStage) {
                    return true; // Stage doesn't exist in the form, so it's modified.
                }
                for (const key of pickKeys) {
                    if (JSON.stringify(stage[key]) !== JSON.stringify(estimateFormStage[key])) {
                        return true; // The property is different, so it's modified.
                    }
                }
                return false; // The stage is not modified.
            });
            if (isModified) {
                this.setIsEstimateLoadedModified(true);
            }
            else {
                this.setIsEstimateLoadedModified(false);
            }
        },
        gotoSchedule() {
            const { userId } = this.authenticatedUser;
            const quote = this.currentSelectedProject?.projectQuotes.find((item) => item.projectQuoteCreator.id === userId);
            const params = {
                projectRefId: this.currentSelectedProject.refId,
                quoteId: this.createdQuoteId || quote.id,
            };
            this.$router.push({ name: 'schedule', params });
        },
        setMembers(event) {
            const { phaseIndex, taskIndex, selectedMembers } = event;
            const existingEntryIndex = this.assignMembers.findIndex((item) => item.phaseIndex === phaseIndex && item.taskIndex === taskIndex);
            if (existingEntryIndex !== -1) {
                const existingEntry = this.assignMembers[existingEntryIndex];
                const newMembers = selectedMembers.filter((member) => !existingEntry.selectedMembers.some((existingMember) => existingMember.id === member.id));
                existingEntry.selectedMembers.push(...newMembers);
            }
            else {
                this.assignMembers.push({ phaseIndex, taskIndex, selectedMembers });
            }
            this.setAssignMembersModal(false);
            this.setSelectedAssignMembers(null);
        },
        onCloseAssignMembersModal() {
            // this.setSelectedAssignMembers(null);
            this.setAssignMembersModal(false);
        }
    }
});
