import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import MaterialForm from '@/core/components/common/forms/estimate-form/MaterialForm.vue';
import MaterialItemForm from '@/core/components/common/forms/estimate-form/MaterialItemForm.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';
export default defineComponent({
    components: {
        MaterialForm,
        MaterialItemForm,
        ActionItemComponent
    },
    emits: [
        'on-input-phase-cost',
        'on-input-task-cost'
    ],
    props: ['isTemplateCreate'],
    data() {
        return {
            materials: []
        };
    },
    computed: {
        ...mapGetters(JOB_TEMPLATE_STORE, ['estimateForm', 'selectedPhaseIndex'])
    },
    methods: {
        addItem() {
            this.estimateForm.projectStages[this.selectedPhaseIndex].projectStageTasks.push({
                name: '',
                amount: 0,
                duration: null,
                durationType: 1,
                total: 0,
                startDate: null,
                endDate: null,
                note: '',
                quantity: null,
            });
        },
        onPhaseCostInput(index) {
            this.$emit('on-input-phase-cost', index);
        },
        onTaskCostInput(index) {
            this.$emit('on-input-task-cost', index);
        },
    },
});
