<template>
    <div class="task-summary flex-column jc-sb">
      <div class="flex-row ai-c gap-half">
        <StatusState boxShadow="none" fontColor="#4A92E5" bgColor="rgba(74, 146, 229, 0.1)" :status="getLabel"/>
        <p class="task-summary__text default-text">{{ task?.name }}</p>
      </div>
      <div class="flex-row ai-c jc-sb">
        <div class="flex-row ai-c">
            <DataWithLabel  v-if="!isMaterial" :noSpaceBetween="true" label="Days" :data="task?.duration" :rowReverse="true" :dataFontBold="true" :gapHalf="true"/>
            <el-divider direction="vertical"/>
            <DataWithLabel :noSpaceBetween="true" label="Total Cost" :data="$filters.formatToCurrency(task.amount)" :rowReverse="true" :dataFontBold="true" :gapHalf="true"/>
            <el-divider direction="vertical"/>
            <DataWithLabel v-if="isMaterial" :noSpaceBetween="true" label="Quantity" :data="task?.quantity" :rowReverse="true" :dataFontBold="true" :gapHalf="true"/>
            <!-- <el-divider direction="vertical"/>
            <DataWithLabel :noSpaceBetween="true" label="Cost" :data="$filters.formatToCurrency(task.total)" :rowReverse="true" :dataFontBold="true" :gapHalf="true"/> -->
        </div>
        <div></div>
      </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import DataWithLabel from '@/core/components/ui/DataWithLabel.vue';
import StatusState from '@/core/components/ui/StatusState.vue';

export default defineComponent({
  components: {
    DataWithLabel,
    StatusState
  },

  props: ['index', 'task', 'isMaterial'],

  computed: {
    getLabel() {
      return this.isMaterial ? `Item ${this.index}` : `Task ${this.index}`;
    }
  }
});
</script>
<style lang="scss" scoped>
  .task-summary {
    border-radius: 10px;
    background: rgba(12, 15, 74, 0.05);
    padding: 1rem;
    box-sizing: border-box;
  }
  .el-divider {
      margin: 0;
  }
</style>
