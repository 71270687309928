<template>
    <div class="data-label flex-row ai-fs jc-sb" :class="{'flex-start': noSpaceBetween, 'flex-row-reverse': rowReverse, 'gap-half': gapHalf}">
       <div class="data-label__wrapper flex-row ai-c">
          <p class="data-label__label default-text" :class="{'normal': dataFontBold}">{{label}}</p>
          <slot name="custom-label"></slot>
       </div>
       <div class="data-label__wrapper">
           <p class="data-label__data default-text" :class="{'bold': dataFontBold}">{{data}}</p>
       </div>
       <div class="data-label__wrapper" v-if="hasCustomData">
        <slot name="customData"></slot>
       </div>
    </div>
</template>
<script>
export default {
  props: ['label', 'data', 'hasCustomData', 'noSpaceBetween', 'rowReverse', 'dataFontBold', 'gapHalf']
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.data-label {
  width: 100%;
  &__label {
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.25px;
    white-space: noWrap;
  }
  &__data {
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.25px;
    color: rgba(12, 15, 74, 0.5);
    text-align: right;
    white-space: noWrap;
  }
  &__wrapper {
    gap: .5rem;
  }

  &__label.normal {
    color: rgba(12, 15, 74, 0.50);
    font-weight: 400;
  }
  &__data.bold {
    color: #0C0F4A;
    font-size: 14px;
    font-weight: 600;
  }
}
.data-label.flex-start {
  justify-content: flex-start !important;
}
@include media-md-max-width() {
  .data-label {
    &__label {
      font-size: 12px;
    }
    &__data {
      font-size: 12px;
    }
  }
}
</style>
