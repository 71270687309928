<template>
    <div class="labour flex-column gap-2">
      <PhaseForm
        :isQuoteAccepted="isQuoteAccepted"
        :isTemplateCreate="isTemplateCreate"
        @on-totalize-duration="onTotalizeDuration"
        @on-input-phase-cost="onPhaseCostInput"
        @on-select-phase-vat="onPhaseVatChange" />

      <div class="flex-column gap-2">
       <ActionItemComponent
          iconSize="25"
          fontSize="14px"
          label="Add task"
          :disable="isFormReadOnly"
          icon="add-button-icon-yellow.svg"
          @call-to-action="addTask"/>
       <TaskForm
         v-for="(task, ii) in estimateForm.projectStages[selectedPhaseIndex].projectStageTasks"
         :isQuoteAccepted="isQuoteAccepted"
         :isTemplateCreate="isTemplateCreate" :index="ii" :key="ii"
         :task="task"
         @on-input-task-cost="onTaskCostInput" />
      </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import PhaseForm from '@/core/components/common/forms/estimate-form/PhaseForm.vue';
import TaskForm from '@/core/components/common/forms/estimate-form/TaskForm.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';

export default defineComponent({
  components: {
    PhaseForm,
    TaskForm,
    ActionItemComponent
  },

  props: ['isTemplateCreate', 'isQuoteAccepted'],

  emits: [
    'on-input-phase-cost',
    'on-input-task-cost',
    'on-select-phase-vat',
    'on-totalize-duration',
  ],

  data() {
    return {
      tasks: []
    };
  },

  computed: {
    ...mapGetters(JOB_TEMPLATE_STORE, ['estimateForm', 'selectedPhaseIndex', 'isFormReadOnly'])
  },

  methods: {
    onTotalizeDuration(index) {
      this.$emit('on-totalize-duration', index);
    },

    onPhaseCostInput(index) {
      this.$emit('on-input-phase-cost', index);
    },

    onPhaseVatChange(index) {
      this.$emit('on-select-phase-vat', index);
    },

    onTaskCostInput(index) {
      this.$emit('on-input-task-cost', index);
    },

    addTask() {
      if (!this.isFormReadOnly) {
        this.estimateForm.projectStages[this.selectedPhaseIndex].projectStageTasks.push({
          name: '',
          amount: 0,
          amountToDisplay: '',
          duration: null,
          durationType: 1,
          total: 0,
          startDate: null,
          endDate: null,
          note: ''
        });
      }
    },
  },
});
</script>
<style lang="scss" scoped>
</style>
