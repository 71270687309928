import { ArrowUpBold } from '@element-plus/icons';
import { mapActions } from 'vuex';
import { PROJECTS_STORE } from '@/store/modules/projects';
export default {
    components: {
        ArrowUpBold
    },
    props: ['data', 'totalFee'],
    computed: {
        getTotalEarning() {
            return this.data.reduce((sum, phase) => sum + this.getTotalCost(phase), 0);
        },
    },
    methods: {
        ...mapActions(PROJECTS_STORE, ['setOpenSummaryEstimateDrawer']),
        openDrawer() {
            this.setOpenSummaryEstimateDrawer(true);
        },
        getTotalCost(phase) {
            if (phase.stageType === 'materials') {
                return phase.subTotal;
            }
            const totalEarning = phase.projectStageTasks.reduce((sum, { total }) => sum + total, 0);
            if (totalEarning > 0) {
                return totalEarning;
            }
            return phase.stageCost;
        },
    }
};
