<template>
    <div class="templates-page flex-column" v-if="isInitialized">
      <section class="templates-page__container grid-2fr" v-if="isOnEstimateForm">
        <EstimateForm
          :isTemplateCreate="false"
          :projectQuote="getProjectQuote"
          :createdQuoteId="createdQuoteId"
          :isQuoteAccepted="isQuoteAccepted"
          :canQuoteSubmit="canQuoteSubmit"
          @on-calculate="calculateFee"/>
        <EstimateSummary
          :isTemplateCreate="false"
          :canQuoteSubmit="canQuoteSubmit"
          :isQuoteAccepted="isQuoteAccepted"
          :summaryTotal="summaryTotal"
          :projectQuote="getProjectQuote"
          :loading="isLoadingSubmit"
          @on-submit-estimate="submitEstimate($event)"
          @route-to-overview="routeToPropertyOverview"/>
      </section>

      <!-- <Loader :show="loadingTemplates" /> -->

      <!-- MODALS -->

      <!-- MOBILE SUMMARY -->
      <MobileSummaryMenu :data="estimateForm.projectStages" :totalFee="totalFee"/>

      <!-- DRAWER -->
      <SummaryDetailsDrawer
        :data="estimateForm.projectStages"
        @on-submit="setSelectDepositModal(true)"
        :projectData="projectData"
        :date="formData?.suggestedStartDate"
        :totalFee="totalFee"/>
    </div>
</template>
<script>
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import EstimateForm from '@/core/components/common/forms/estimate-form/EstimateForm.vue';
import EstimateSummary from '@/core/components/common/forms/estimate-form/EstimateSummary.vue';
// import Loader from '@/core/components/ui/Loader.vue';
import { SYSTEM_DATE_FORMAT } from '@/core/constants';
import estimateState from '@/core/constants/estimate';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/messages';
import { ProjectQuoteStatusEnum, ProjectStageTypeEnum } from '@/core/packages/shared-library';
import CurrencyFilter from '@/filters/currency.filter';
import MobileSummaryMenu from '@/modules/project-estimate/components/MobileSummaryMenu.vue';
import SummaryDetailsDrawer from '@/modules/project-estimate/components/SummaryDetailsDrawer.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USER_WORKSTATION_MEMBERS_STORE } from '@/store/modules/user-workstation-member';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    EstimateSummary,
    EstimateForm,
    // Loader,
    MobileSummaryMenu,
    SummaryDetailsDrawer
  },
  data() {
    return {
      summaryTotal: null,
      isInitialized: false,
      projectData: null,

      isQuoteAccepted: false,
      canQuoteSubmit: true,
      isQuoteConfirmed: false,

      isLoadingSubmit: false,

      createdQuoteId: null,

      estimateFormId: null,
      estimateState
    };
  },

  computed: {
    ...mapGetters(JOB_TEMPLATE_STORE, [
      'showUseTemplateModal',
      'estimateForm',
      'defaultEstimateForm',
      'isOnEstimateForm',
      'confirmDeleteModal',
      'defaultProjectStageForm',
      'formData',
      'hasLoadedTemplate',
      'loadedEstimate',
      'isFormReadOnly',
      'assignMembers',
      'selectedPhaseIndex'
    ]),

    ...mapGetters(USERS_STORE, ['user']),

    ...mapGetters(PROJECTS_STORE, [
      'currentSelectedProject'
    ]),

    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),

    ...mapGetters(PROJECT_QUOTES_STORE, [
      'totalFee',
      'type'
    ]),

    getProjectQuote() {
      const { userId } = this.authenticatedUser;
      const quote = this.currentSelectedProject?.projectQuotes.find((item) => item.projectQuoteCreator.id === userId);

      return quote;
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions(JOB_TEMPLATE_STORE, [
      'getMyJobTemplates',
      'getJobTemplate',
      'setShowUseTemplateModal',
      'setEstimateForm',
      'setSelectedJobTemplate',
      'setTemplateName',
      'setIsOnEstimateForm',
      'setConfirmDeleteModal',
      'deleteMyJobTemplate',
      'setFormData',
      'setShowSubmitEstimateConfirmationModal',
      'setSelectDepositModal',
      'setShowCreateTemplateConfirmationModal',
      'createMyJobTemplate',
      'setHasLoadedTemplate',
      'setLoadedEstimate',
      'setIsFormReadOnly',
      'setLockTemplateModifiedChecker',
      'setMemberList',
      'setIsFormReady',
      'setShowUpdateEstimateConfirmationModal',
      'setSelectedPhaseIndex'
    ]),

    ...mapActions(PROJECTS_STORE, [
      'getProjectByRefId',
      'setCurrentSelectedProject',
      'setHasGetProjectFinished'
    ]),

    ...mapActions(PROJECT_QUOTES_STORE, [
      'calculateServiceFee',
      'createProjectQuote',
      'updateProjectQuote',
      'updateProjectQuoteStatus',
      'setTotalFee',
      'setType'
    ]),

    ...mapActions(USER_WORKSTATION_MEMBERS_STORE, [
      'getUserWorkstationMembers',
    ]),

    async initialize() {
      this.setTotalFee(0);
      this.setIsOnEstimateForm(true);
      this.setSelectedPhaseIndex(0);
      const { projectRefId } = this.$route.params;

      try {
        const projectData = await this.getProjectByRefId(projectRefId);
        this.projectData = projectData;

        this.setHasGetProjectFinished(true);
        this.setCurrentSelectedProject(projectData);

        this.formData.suggestedStartDate = projectData.startDate
          ? moment(projectData.startDate).format(SYSTEM_DATE_FORMAT) : moment().format(SYSTEM_DATE_FORMAT);
        // }

        const { projectQuotes: [estimateForm] } = projectData;

        if (estimateForm) {
          this.setIsFormReadOnly(this.isUneditableForm(estimateForm));

          this.formData.suggestedStartDate = estimateForm.suggestedStartDate;
          const projectStages = estimateForm.projectStages.map((x) => {
            const isMaterial = x.stageType === ProjectStageTypeEnum.MATERIALS;

            if (x.projectStageTasks && x.projectStageTasks.length > 0) {
              const projectStageTasks = x.projectStageTasks.map((y) => {
                const total = isMaterial ? y.amount * y.quantity : y.amount * y.duration;
                return Object.assign(y, {
                  durationType: 1,
                  total,
                  note: y.notes,
                  amountToDisplay: CurrencyFilter.formatCurrencyWithoutSymbol(y.amount)
                }); // Add the note property
              });
              return Object.assign(x, {
                isNew: false,
                subTotalDisplay: CurrencyFilter.formatCurrencyWithoutSymbol(x.subTotal),
                isUsePhaseTotal: x.projectStageTasks[0].duration === 0 && x.projectStageTasks[0].amount === 0,
                totalCostWithVat: this.getPhaseCostWithVat(x.stageCost, x.vat),
                durationType: 1,
                projectStageTasks,
                note: x.description, // Rename description to note
              });
            }
            return {
              ...x,
              subTotalDisplay: CurrencyFilter.formatCurrencyWithoutSymbol(x.subTotal),
              attachments: x.projectStageAttachments,
              note: x.description, // Rename description to note
            };
          });
          this.setEstimateForm(Object.assign(estimateForm, { projectStages }));
          this.setType(estimateForm.type);
          // this.setFormData({ ...this.suggestedStartDate });
          // this.isShowScheduleCta = true;

          await this.calculateFee();

          this.isQuoteAccepted = this.estimateForm.projectQuoteStatus?.id === ProjectQuoteStatusEnum.QUOTE_ACCEPTED;

          this.canQuoteSubmit = [ProjectQuoteStatusEnum.QUOTE_REJECTED, ProjectQuoteStatusEnum.QUOTE_SUBMITTED].includes(this.estimateForm.projectQuoteStatus?.id);

          this.isQuoteConfirmed = this.estimateForm.projectQuoteStatus?.id === ProjectQuoteStatusEnum.QUOTE_CONFIRMED;

          if (this.isQuoteConfirmed) {
            this.routeToPropertyOverview();
          }
        } else {
          this.setEstimateForm();
          this.setIsFormReadOnly(false);
          this.setFormData({ suggestedStartDate: null });
          this.setType(estimateState.VARIABLE);
        }

        // get member list
        const { user, getUserWorkstationMembers } = this;
        const params = {
          userId: user.id,
          keyword: ''
        };

        await getUserWorkstationMembers(params)
          .then((response) => {
            this.setMemberList(response);
          })
          .catch(() => {});

        this.isInitialized = true;
      } catch (error) {
        this.$notify.error({
          message: error && error?.message ? error?.message : 'Project not found.'
        });
      }
    },

    setSummaryTotal(summary) {
      this.summaryTotal = summary;
    },

    isUneditableForm(estimateForm) {
      return parseInt(estimateForm.projectQuoteStatus.id, 10) >= 2;
    },

    getPhaseCostWithVat(cost, vat) {
      if (vat === null || vat === undefined) {
        return CurrencyFilter.formatToCurrency(cost.toString());
        // return parseFloat(cost).toFixed(2);
      }
      return CurrencyFilter.formatToCurrency((cost + (cost * vat)).toString());
      // return (parseFloat(cost) + (parseFloat(cost) * vat)).toFixed(2);
    },

    routeToPropertyOverview() {
      this.$router.push({ name: 'overview' });
    },

    async calculateFee() {
      const totalFee = await this.calculateServiceFee({
        projectId: this.currentSelectedProject?.id,
        projectStages: this.estimateForm.projectStages,
      });
      this.setTotalFee(totalFee);
    },

    async submitEstimate(saveAsTemplate) {
      if (this.isFormReadOnly) {
        this.$notify.error({
          message: 'Estimate is already accepted!.'
        });
        return;
      }

      this.isLoadingSubmit = true;

      const createPhases = this.estimateForm.projectStages.map((x) => {
        const subTotalToNumber = CurrencyFilter.toNumber(x.subTotal);
        if (x.stageType === ProjectStageTypeEnum.MATERIALS) {
          return {
            id: x.id,
            name: x.name,
            stageType: x.stageType,
            totalDuration: 0,
            stageCost: 0,
            subTotal: parseFloat(subTotalToNumber),
            vat: x.vat || 0,
            startDate: null, // new optional
            endDate: null, // new optional
            attachments: x.attachments,
            requiredDeposit: x.requiredDeposit,
            projectStageTasks: x.projectStageTasks.map((y) => {
              return {
                name: y.name,
                quantity: String(y.quantity) || '0',
                duration: 0,
                amount: parseFloat(y.amount) || 0,
                startDate: null, // new optional
                endDate: null, // new optional
              };
            })
          };
        }

        return {
          id: x.id,
          name: x.name,
          stageType: x.stageType,
          totalDuration: x.totalDuration,
          stageCost: parseFloat(x.stageCost),
          subTotal: parseFloat(subTotalToNumber),
          vat: x.vat,
          startDate: moment(x.startDate).format('YYYY-MM-DD'),
          endDate: ((x.isNew && !x.isUsePhaseTotal) || !x.endDate) ? moment(this.formData.suggestedStartDate).add(x.totalDuration * x.durationType, 'days').format('YYYY-MM-DD') : x.endDate,
          attachments: x.attachments,
          requiredDeposit: x.requiredDeposit,
          description: x.note,
          projectStageTasks: x.projectStageTasks.map((y, index) => {
            const result = this.assignMembers.find((item) => item.taskIndex === index);
            return {
              projectStageTaskMembers: result?.selectedMembers || [],
              name: y.name,
              notes: y.note,
              quantity: null,
              duration: y.duration || 0,
              amount: parseFloat(y.amount) || 0,
              startDate: ((x.isNew && !x.isUsePhaseTotal) || !y.startDate) ? moment(x.startDate).format('YYYY-MM-DD') : y.startDate,
              endDate: ((x.isNew && !x.isUsePhaseTotal) || !y.endDate) ? moment(this.formData.suggestedStartDate).add(y.duration * y.durationType, 'days').format('YYYY-MM-DD') : y.endDate,
            };
          })
        };
      });

      const formData = {
        projectId: this.projectData.id,
        // notes: this.estimateForm.notes,
        projectStages: createPhases,
        projectQuoteAttachments: this.estimateForm.projectQuoteAttachments,
        projectQuoteStatusId: this.estimateForm.projectQuoteStatus ? this.estimateForm.projectQuoteStatus.id : '1',
        suggestedStartDate: moment(this.formData.suggestedStartDate).format('YYYY-MM-DD'),
        type: this.type,
      };

      try {
        if (this.projectData.projectQuotes.length > 0) {
          await this.updateEstimate(formData);
        } else {
          await this.createProjectQuote({ projectId: this.projectData.id, projectQuoteId: this.estimateForm.id, formData })
            .then(async (response) => {
              this.createdQuoteId = response.id;
              if (saveAsTemplate) {
                const { params } = this.$route;
                const uniqueId = params.projectRefId.slice(0, 5);
                let name = '';
                if (this.hasLoadedTemplate) {
                  name = `${this.loadedEstimate.name}: ${uniqueId}`;
                } else {
                  name = `${this.currentSelectedProject.name}: ${uniqueId}`;
                }

                await this.saveEstimateAsTemplate().then(() => {
                  this.$notify.success({
                    title: 'New Template created',
                    message: `Template name: ${name}`,
                  });
                }).catch(() => {})
                  .finally(() => {
                    this.setLoadedEstimate(null);
                    this.setHasLoadedTemplate(false);
                  });
              }
              await this.initialize();
              this.setLockTemplateModifiedChecker(false);
              this.$notify.success({
                title: 'Successfull',
                message: 'Estimate successfully submitted',
              });
            })
            .catch(() => {
              this.$notify.error({
                message: 'Pleaes fill in the required fields.'
              });
            });
        }
        this.isLoadingSubmit = false;
        this.isShowScheduleCta = true;
      } catch (e) {
        this.isLoadingSubmit = false;
      }

      this.onCloseModals();
    },

    async updateEstimate(formData) {
      // eslint-disable-next-line no-param-reassign
      formData.projectQuoteStatusId = ProjectQuoteStatusEnum.QUOTE_SUBMITTED; // force submission for now
      await this.updateProjectQuote({
        projectId: this.projectData.id,
        projectQuoteId: this.projectData.projectQuotes[0].id,
        formData
      })
        .then(async () => {
          await this.initialize();
          this.setLockTemplateModifiedChecker(false);
          this.$notify.success({
            title: 'Successfull',
            message: 'Estimate successfully updated',
          });
        })
        .catch(() => {
          this.$notify.error({
            message: 'Error updating estimate. Please try again.'
          });
        })
        .finally(() => {
          if (this.hasLoadedTemplate) {
            this.setLoadedEstimate(null);
            this.setHasLoadedTemplate(false);
          }
        });
    },

    async saveEstimateAsTemplate() {
      const { params } = this.$route;
      let name = '';
      const uniqueId = params.projectRefId.slice(0, 5);

      if (this.hasLoadedTemplate) {
        name = `${this.loadedEstimate.name}: ${uniqueId}`;
      } else {
        name = `${this.currentSelectedProject.name}: ${uniqueId}`;
      }

      delete this.estimateForm.startDate;
      const formData = {
        name,
        meta: this.estimateForm
      };

      this.isSubmitting = true;
      this.createMyJobTemplate(formData).then()
        .catch(() => {
          this.$notify.error({
            title: 'Template Creation Failed',
            message: GENERIC_ERROR_MESSAGE,
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    onCloseModals() {
      this.setShowCreateTemplateConfirmationModal(false);
      this.setSelectDepositModal(false);
      this.setShowSubmitEstimateConfirmationModal(false);
      this.setShowUpdateEstimateConfirmationModal(false);
    },
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.templates-page {
  position: relative;
  height: 100%;

  &__container {
    padding-bottom: 2rem;
    box-sizing: border-box;
  }

  &__container.sticky {
    background: #F1F6FB;
    position: sticky;
    top: 0;
    z-index: 101;
    padding-bottom: 1rem;
    box-sizing: border-box;
  }
  &__container.grid {
    grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
    gap: 2rem;
  }
  &__container.grid-2fr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  &__container.jc-fe {
    justify-content: flex-end !important;
  }
}

@media screen and (max-width: 1350px) {
  .templates-page {
    &__container.grid-2fr {
      grid-template-columns: 1fr;
    }
  }
}
</style>
