<template>
    <div class="added-item flex-row ai-c jc-sb">
      <p class="added-item__text default-text">{{emailValue}} <span>(INVITE PENDING)</span></p>
      <div class="flex-row ai-c" style="gap: .5rem;">
        <el-select class="added-item__select" style="width:100px;" v-model="roleValue" filterable placeholder="Role">
            <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            />
        </el-select>
        <el-divider direction="vertical" style="margin: 0"/>
        <inline-svg class="cursor-pointer" :src="require('@/assets/icons/delete-icon.svg')" @click="onRemove(addedEmail)"/>
      </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
  },
  props: ['added', 'options'],

  emits: ['on-remove'],

  computed: {

    addedEmail() {
      let item;
      for (let i = 0; i < this.added.length; i++) {
        item = this.added[i].email;
      }
      return item;
    },

    addedRole() {
      let item;
      for (let i = 0; i < this.added.length; i++) {
        item = this.added[i].role;
      }
      return item;
    }
  },

  created() {
    this.roleValue = this.addedRole;
    this.emailValue = this.addedEmail;
  },

  methods: {
    onRemove(member) {
      this.$emit('on-remove', member);
    }
  }
});
</script>
<style lang="scss" scoped>
.added-item {
  width: 100%;
  gap: .5rem;

  &__text {
    color: #0C0F4A;
    font-size: 15px;
    line-height: 30px;
    font-weight: 500;

    span {
      color: rgba(12, 15, 74, 0.50);
    }
  }
}
:deep(.el-input__inner) {
  border: none;
  padding-left: 0;
}
</style>
