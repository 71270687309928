<template>
<BaseModal
  :show="show"
  :showCancelBtn="false"
  :showSubmitBtn="false"
  :showDefaultCloseBtn="false"
  @cancel="cancel"
  :hasActions="false"
>
  <div class="invite-members-modal flex-column jc-sb">
    <section class="modal-header mb-1 flex-row ai-fs jc-sb">
      <div class="modal-header__wrapper flex-column">
        <p class="modal-header__title default-text">Invite others trades to join this estimate</p>
        <p class="modal-header__description default-text">You can invite other trades via their email or workstation name.</p>
      </div>
      <div class="modal-header__icon">
       <inline-svg @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
      </div>
    </section>
    <section class="modal-body flex-column">
      <MembersInviteForm :hasUpdated="hasUpdated" @update-invite-list="updateInviteList($event)" inputLabel="Email or workstation name"/>
    </section>
    <section class="invite-members-modal__footer flex-row ai-c jc-fs">
      <Button :isActive="initialAdded.length" :buttonText="isUpdating ? 'saving' : 'done'" :loading="isUpdating" @handle-click="inviteMembers"/>
    </section>
  </div>
</BaseModal>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import MembersInviteForm from '@/core/components/members/MembersInviteForm.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
import { USER_WORKSTATION_MEMBERS_STORE } from '@/store/modules/user-workstation-member';

export default defineComponent({
  name: 'invite-members-modal',
  emits: ['invite', 'on-close'],
  props: ['hasUpdated'],
  components: {
    BaseModal,
    Button,
    MembersInviteForm
  },

  watch: {
    hasUpdated: {
      immediate: true,
      deep: true,
      handler() {
        this.initialAdded = [];
        this.isUpdating = false;
      }
    }
  },

  computed: {
    ...mapGetters(USER_WORKSTATION_MEMBERS_STORE, ['recentlyAdded'])
  },

  data: () => {
    return {
      initialAdded: [],
    };
  },
  methods: {
    cancel() {
      this.$emit('on-close');
    },
    updateInviteList(event) {
      this.initialAdded = event;
    },

    inviteMembers() {
      this.isUpdating = true;
      this.$emit('invite', this.initialAdded);
    }
  }
});
</script>
<style lang="scss" scoped>
.invite-members-modal {
  position: relative;
  min-width: 500px;
  gap: 1.5rem;

    .modal-header {
      &__icon {
        cursor: pointer;
      }
      &__description {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.4000000059604645px;
      }
      &__wrapper {
        gap: 0;
      }
      &__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
      }
    }
    .modal-body {
      gap: 1.5rem;
      &__header-container {
      }
      &__container {
      }
    }
}
</style>
