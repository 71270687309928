import CurrencyFilter from '@/filters/currency.filter';
export default {
    calculateTotalEarning(estimateForm) {
        return estimateForm?.projectStages.reduce((sum, phase) => sum + (phase.stageCost || phase.subTotal), 0);
    },
    calculateTotalVatAmount(estimateForm) {
        return estimateForm?.projectStages.reduce((sum, phase) => sum + this.getTotalVat(phase), 0);
    },
    calculateTotalCustomerPayment(estimateForm, totalFee) {
        const totalEarning = this.calculateTotalEarning(estimateForm);
        return CurrencyFilter.formatToCurrency((Number(totalEarning) + Number(totalFee)).toString());
    },
    calculateTotalServiceFeePercent(estimateForm, totalFee) {
        return Number(((100 / this.calculateTotalEarning(estimateForm)) * totalFee)).toFixed(2);
    },
    calculateTotalCustomerPaymentRaw(estimateForm, totalFee) {
        const totalEarning = this.calculateTotalEarning(estimateForm);
        return (Number(totalEarning) + Number(totalFee));
    },
    formatCurrency(amount) {
        return CurrencyFilter.formatToCurrency(amount);
    },
    formattedTotalEarning(estimateForm) {
        return this.formatCurrency(this.calculateTotalEarning(estimateForm));
    },
    getTotalVat(phase) {
        if (phase.vat === undefined) {
            return 0;
        }
        const totalVatAmount = phase.projectStageTasks.reduce((sum, x) => sum + Number(parseFloat(x.stageCost)) * Number(x.vat), 0);
        if (totalVatAmount > 0) {
            return totalVatAmount;
        }
        return Number(parseFloat(phase.stageCost)) * Number(phase.vat);
    },
};
